import React, { useState } from "react";
import { Box, Flex, Heading, Text, useBreakpointValue } from "@chakra-ui/react";
import { brandCardProps } from "./types";
import { Ico } from "@/assets/icons";

const FilterItem: React.FC<brandCardProps> = ({ brand, handleClick }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const [isActive, setIsActive] = useState(false);

  const handleMouseDown = () => setIsActive(true);
  const handleMouseUp = () => setIsActive(false);

  return (
    <Box
      transition={"all 0.3s"}
      userSelect={"none"}
      border={"1px solid"}
      borderColor={"neutral.100"}
      w={isMobile ? "100%" : "25%"}
      minW={"20%"}
      pl={5}
      borderRadius={"8px"}
      cursor={"pointer"}
      py={5}
      bg={isActive ? "primary.400" : "white"}
      color={isActive ? "white" : "auto"}
      _hover={{
        borderColor: "primary.400",
      }}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
      onMouseLeave={() => setIsActive(false)}
    >
      <Flex gap={3} alignItems={"center"}>
        {isActive ? brand.cleanIcon : brand.icon}
        <Flex
          justifyContent={"space-between"}
          w={"100%"}
          alignItems={"center"}
          pr={5}
        >
          <Box>
            <Text variant={"caption"}>{brand.description}</Text>
            <Heading as="h6">{brand.name}</Heading>
          </Box>
          <Ico.ChevronRight fontSize={"24px"} />
        </Flex>
      </Flex>
    </Box>
  );
};

export default FilterItem;
