/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { ApolloProvider } from "@apollo/client";
import React, { Suspense } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";

import { ThemeSwitcher } from "./ThemeSwitcher";
import { apolloClient } from "./apolloClient";
import i18next from "./i18n";
import "./index.css";
import TagManager from "react-gtm-module";
import { config } from "@/config";

import "./assets/fonts/Inter/Inter-Variable.ttf";
import "./assets/fonts/Poppins/Poppins-SemiBold.ttf";
import GrowthBookWrapper from "./components/GrowthBookWrapper";
import { UserProvider } from "./providers/UserProvider";
import { UserSettingsProvider } from "./components/Users/Settings/Provider";
import ZoomProvider from "./components/LiveSessionsZoom/context/ZoomProvider";
import { CustomNavigationProvider } from "./providers/Navigation/Provider";

const tagManagerArgs = {
  gtmId: config.VITE_GTM,
};

TagManager.initialize(tagManagerArgs);

const container = document.getElementById("root");
if (!container) {
  throw new Error("Root element not found");
}
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Suspense fallback={null}>
      <I18nextProvider i18n={i18next}>
        <ApolloProvider client={apolloClient}>
          <UserProvider>
            <CustomNavigationProvider>
              <GrowthBookWrapper>
                <UserSettingsProvider>
                  <ZoomProvider>
                    <DndProvider backend={HTML5Backend}>
                      <ThemeSwitcher />
                    </DndProvider>
                  </ZoomProvider>
                </UserSettingsProvider>
              </GrowthBookWrapper>
            </CustomNavigationProvider>
          </UserProvider>
        </ApolloProvider>
      </I18nextProvider>
    </Suspense>
  </React.StrictMode>
);
