import React from "react";
import { Avatar, Flex, Heading, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export interface Mentor {
  name_mentor: string;
  link_imagen: string;
  description: string;
  __typename: string;
}
interface ProgramMentorsProps {
  mentors: Mentor[];
}

export const ProgramMentors: React.FC<ProgramMentorsProps> = ({ mentors }) => {
  const { t } = useTranslation();
  return (
    <Flex flexDir={"column"} px={{ base: 4, xl: 0 }} w={"944px"} pb={8} gap={4}>
      <Heading textAlign={"start"}>{t("Meet the mentors")}</Heading>
      <Flex
        gap={8}
        flexDir={{ base: "column", md: "row" }}
        alignItems={"start"}
        wrap={"wrap"}
      >
        {mentors.map((mentor) => {
          return (
            <Flex key={mentor.name_mentor} gap={2} w={"204px"}>
              <Avatar name="Oshigaki Kisame" src={mentor.link_imagen} />
              <Flex flexDir={"column"} gap={1}>
                <Text fontWeight={"bold"}>{mentor.name_mentor}</Text>
                <Text>{mentor.description}</Text>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};
