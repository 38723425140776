import { AppRoute } from "@/AppRoute";
import {
  AccordionButton,
  AccordionItem,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { activeStyles, useIsCurrentPathActive } from "./utils";

export interface NavigationTooltipButtonProps {
  label: string;
  isMenuExpanded: boolean;
  route: AppRoute;
  icon: React.ReactNode;
}

const NavigationTooltipButton: React.FC<NavigationTooltipButtonProps> = ({
  label,
  route,
  isMenuExpanded,
  icon,
}) => {
  const navigate = useNavigate();

  return (
    <AccordionItem
      w={!isMenuExpanded ? "36px !important" : "auto"}
      h={!isMenuExpanded ? "36px !important" : "auto"}
      border={"none"}
      key={`${label}-button`}
    >
      <Tooltip
        label={label}
        aria-label={label}
        placement="right"
        bg={"secondary.300"}
        fontSize={"12px"}
        px={"12px"}
        py={"8px"}
        borderRadius={"8px"}
        display={isMenuExpanded ? "none" : "auto"}
      >
        <AccordionButton
          p={"8px"}
          gap={"8px"}
          borderRadius={"6px"}
          justifyContent={isMenuExpanded ? "flex-start" : "center"}
          onClick={() => navigate(route)}
          _hover={{ bg: "neutral.50" }}
          {...(useIsCurrentPathActive([route]) && activeStyles.button)}
        >
          <span
            style={{
              fontSize: "20px",
              ...(useIsCurrentPathActive([route]) && activeStyles.icon),
            }}
          >
            {icon}
          </span>
          {isMenuExpanded && (
            <Text variant={"caption"} flex={1} textAlign={"start"}>
              {label}
            </Text>
          )}
        </AccordionButton>
      </Tooltip>
    </AccordionItem>
  );
};

export default NavigationTooltipButton;
