import { useCallback, useState } from "react";
import { NavigationContext } from "./Context";
import { AppRoute, PublicRoute } from "@/AppRoute";
import { EntityName } from "@/schemaTypes";

interface CustomNavigationProviderProps {
  children: React.ReactNode;
}
const PRODUCT_OF_INTEREST_KEY = "ProductName";
const PRODUCT_TYPE_KEY = "ProductType";

const CustomNavigationProvider = ({
  children,
}: CustomNavigationProviderProps): JSX.Element => {
  const [redirectRoute, setRedirectRoute] = useState<
    AppRoute | PublicRoute | null
  >();
  const [productOfInterest, setProductOfInterest] = useState<string | null>(
    null
  );
  const [productTypeOfInterest, setProductTypeOfInterest] = useState<
    EntityName | string | null
  >(null);

  const updateRedirectRoute = useCallback(
    (route: AppRoute | PublicRoute | null) => {
      if (route) {
        setRedirectRoute(route);
      }
    },
    []
  );

  const clearProductOfInterest = useCallback(() => {
    localStorage.removeItem(PRODUCT_OF_INTEREST_KEY);
    localStorage.removeItem(PRODUCT_TYPE_KEY);
  }, []);

  const updateProductOfInterest = useCallback(
    (
      productParamTypeOfInterest: EntityName | string | null,
      productParamOfInterest: string | null
    ) => {
      clearProductOfInterest();
      if (productParamOfInterest) {
        setProductOfInterest(productParamOfInterest);
        localStorage.setItem(PRODUCT_OF_INTEREST_KEY, productParamOfInterest);
      }
      if (productParamTypeOfInterest) {
        setProductTypeOfInterest(productParamTypeOfInterest);
        localStorage.setItem(PRODUCT_TYPE_KEY, productParamTypeOfInterest);
      }
    },
    []
  );

  const getProductOfInterestFromStorage = (): {
    productTypeOfInterest: EntityName | null | string;
    productOfInterest: string | null;
  } => {
    const interest = localStorage.getItem(PRODUCT_OF_INTEREST_KEY);
    const typeOfInterest = localStorage.getItem(PRODUCT_TYPE_KEY) as EntityName;

    if (!interest || !typeOfInterest)
      return { productOfInterest: null, productTypeOfInterest: null };

    setProductOfInterest(interest);
    setProductTypeOfInterest(typeOfInterest);

    return {
      productOfInterest: productOfInterest,
      productTypeOfInterest: productTypeOfInterest,
    };
  };

  return (
    <NavigationContext.Provider
      value={{
        redirectRoute,
        productOfInterest,
        productTypeOfInterest,
        updateRedirectRoute,
        updateProductOfInterest,
        clearProductOfInterest,
        getProductOfInterestFromStorage,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export { CustomNavigationProvider };
