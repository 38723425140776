import { config } from '@/config';
import { useUser } from '@/providers/useUser';
import React, { useEffect, useState } from 'react';

/**
 * Declare the types for the embeddedservice_bootstrap object
 * so that TypeScript is aware of their existence on the window.
 */
declare global {
  interface Window {
    embeddedservice_bootstrap?: {
      settings: {
        language: string;
      };
      prechatAPI: {
        setHiddenPrechatFields: (fields: Record<string, string>) => void;
      };
      init: (
        orgId: string,
        developerName: string,
        siteURL: string,
        options?: Record<string, unknown>
      ) => void;
    };
  }
}

/**
 * Custom hook to dynamically load a script in React.
 */
function useLoadScript(scriptUrl: string) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const scriptElement = document.createElement('script');
    scriptElement.src = scriptUrl;
    scriptElement.type = 'text/javascript';

    // On success, mark as loaded
    scriptElement.onload = () => setLoaded(true);
    // On error, just log or handle the error
    scriptElement.onerror = () => {
      console.error(`Failed to load script: ${scriptUrl}`);
      setLoaded(false);
    };

    document.body.appendChild(scriptElement);

    // Cleanup the script when component unmounts
    return () => {
      document.body.removeChild(scriptElement);
    };
  }, [scriptUrl]);

  return loaded;
}

const SalesforceChat: React.FC = () => {
  // Load the external script via our hook
  const scriptLoaded = useLoadScript(
    'https://smartbeemo--parcial.sandbox.my.site.com/ESWChatPortalFreemium1738779437393/assets/js/bootstrap.min.js'
  );
  const { isPro, user } = useUser();
  useEffect(() => {
    // Only attempt to initialize if the script loaded successfully
    if (!scriptLoaded) return;
    if (!isPro) return;
    if (!window.embeddedservice_bootstrap) {
      console.warn('embeddedservice_bootstrap is not available on window.');
      return;
    }

    try {
      // Set language
      window.embeddedservice_bootstrap.settings.language = 'es';

      // Listen for the Embedded Messaging Ready event
      const onEmbeddedMessagingReady = () => {
        console.log('Received the onEmbeddedMessagingReady event...');
        window.embeddedservice_bootstrap?.prechatAPI.setHiddenPrechatFields({
          Service_openingDialog: 'Dialog2',
          Portal_Id: user ? user.id : '67476dd049375023e5b58a36',
        });
        // eslint-disable-next-line no-console
        console.log(user ? user.id : '67476dd049375023e5b58a36')
      };

      window.addEventListener('onEmbeddedMessagingReady', onEmbeddedMessagingReady);
      // Initialize the embedded service
      window.embeddedservice_bootstrap.init(
        '00DEc000001PCS1',
        config.SALESFORCE_CHAT_TARGET_ENV,
        'https://smartbeemo--parcial.sandbox.my.site.com/ESWChatPortalFreemium1738779437393',
        {
          scrt2URL: 'https://smartbeemo--parcial.sandbox.my.salesforce-scrt.com',
        }
      );

      // Clean up the listener on unmount
      return () => {
        window.removeEventListener('onEmbeddedMessagingReady', onEmbeddedMessagingReady);
      };
    } catch (err) {
      console.error('Error loading Embedded Messaging: ', err);
    }
  }, [scriptLoaded]);

  return null;
};

export default SalesforceChat;
