/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React, { useEffect, useState } from "react";
import { SignInCard } from "./SignInCard";
import { SignUpCard } from "./SignUpCard";

interface onSignIn {
  onSignIn?: () => void;
  onSignUp?: () => void;
  hasContainer: boolean;
  isLoginVisible?: boolean;
  signInMessage?: string;
  onLoginVisibleChange?: (isLoginVisible: boolean) => void;
}

export const SignUser: React.FC<onSignIn> = ({
  onSignIn,
  hasContainer,
  onSignUp,
  signInMessage,
  isLoginVisible = true,
  onLoginVisibleChange,
}) => {
  const [isLoggingInState, setIsLoggingIn] = useState(isLoginVisible);
  useEffect(() => {
    setIsLoggingIn(isLoginVisible);
  }, [isLoginVisible]);

  const toggleSignUp = () => {
    setIsLoggingIn(!isLoggingInState);
    onLoginVisibleChange?.(!isLoggingInState);
  };

  const handleSignUp = () => {
    setIsLoggingIn(true);
    onSignUp?.();
  };

  return isLoggingInState ? (
    <SignInCard
      onSignIn={onSignIn}
      hasContainer={hasContainer}
      onSignUpClick={toggleSignUp}
      message={signInMessage}
    />
  ) : (
    <SignUpCard hasContainer={hasContainer} onSignUp={handleSignUp} />
  );
};
