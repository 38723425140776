import { useContext } from "react";
import { MentorContext } from "./MentorProvider";

export const useMentor = () => {
  const context = useContext(MentorContext);

  if (!context) {
    throw new Error("useMentor debe ser usado dentro de un MentorProvider");
  }

  return context;
};
