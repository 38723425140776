import { Button, Text, VStack } from "@chakra-ui/react";
import { activeStyles, useIsCurrentPathActive } from "../utils";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "@/AppRoute";
import { Trans } from "react-i18next";

interface InformationMenuItem {
  label: string;
  route: AppRoute;
}
interface InformationMenuProps {
  isMenuExpanded: boolean;
}
const MenuData: InformationMenuItem[] = [
  {
    label: "frequent questions",
    route: AppRoute.FrequentQuestions,
  },
  { label: "Contact us", route: AppRoute.ContactUs },
  { label: "Terms and Conditions", route: AppRoute.TermsAndConditions },
  {
    label: "privacyPolicyTranslations.header.Privacy Policy",
    route: AppRoute.PrivacyPolicies,
  },
];

export const InformationMenu = ({ isMenuExpanded }: InformationMenuProps) => {
  const navigate = useNavigate();
  return (
    <VStack spacing={0} align="stretch">
      {MenuData.map((item) => (
        <Button
          hidden={!isMenuExpanded}
          key={item.label}
          w={"100%"}
          py={"6px"}
          pl={2}
          pr={10}
          bg={"transparent"}
          borderRadius={"6px"}
          justifyContent={"flex-start"}
          onClick={() => navigate(item.route)}
          _hover={{ bg: "neutral.50" }}
          {...(useIsCurrentPathActive([item.route]) && activeStyles.button)}
        >
          {isMenuExpanded && (
            <Text fontSize={"sm"} flex={1} textAlign={"start"}>
              <Trans>{item.label}</Trans>
            </Text>
          )}
        </Button>
      ))}
    </VStack>
  );
};
