import { FormControl, FormLabel, Text } from "@chakra-ui/react";
import { DateTime } from "luxon";
import React from "react";
import ReactDatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePickerField.css";

registerLocale("es", es);

interface DatePickerProps {
  value?: Date | null;
  onChange?: (date: Date, isValid?: boolean) => void;
  isRequired?: boolean;
  label?: string;
  legend?: React.ReactNode;
  showTimeSelect?: boolean;
  isDisabled?: boolean;
}

export const DatePickerField: React.FC<DatePickerProps> = ({
  value,
  onChange,
  isRequired,
  label,
  legend,
  showTimeSelect = false,
  isDisabled = false,
}) => {
  const initialValue =
    typeof value === "string" ? DateTime.fromISO(value).toJSDate() : value;
  const isInvalid = initialValue === null;

  const handleChange = (date: Date) => {
    if (onChange) {
      onChange(date, !isInvalid);
    }
  };

  return (
    <FormControl
      id="date-picker"
      isRequired={isRequired}
      pb="3"
      isDisabled={isDisabled}
    >
      {label && (
        <FormLabel fontWeight="medium" color="gray.700">
          {label}
        </FormLabel>
      )}
      <ReactDatePicker
        locale="es"
        disabled={isDisabled}
        showTimeSelect={showTimeSelect}
        className={isInvalid ? "invalid" : ""}
        selected={initialValue}
        onChange={handleChange}
        dateFormat={showTimeSelect ? "dd/MM/yyyy hh:mm aa" : "dd/MM/yyyy"}
        timeFormat="hh:mm aa"
        timeIntervals={60}
        timeCaption="Hora"
        placeholderText="Seleccionar fecha"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        todayButton="Hoy"
        calendarStartDay={1}
        monthsShown={1}
        previousMonthButtonLabel="Mes anterior"
        nextMonthButtonLabel="Mes siguiente"
      />
      {legend && (
        <Text fontSize="sm" color="gray.500" mt="1" ml="1" fontStyle="italic">
          {legend}
        </Text>
      )}
    </FormControl>
  );
};
