import { Img } from "@/assets/imgs";

export const arraySteps = [
  {
    title: "¡Bienvenid@ a smartBeemo!",
    description:
      "Con más contenido que nunca, aprender a montar tu tienda de Ecommerce nunca ha sido tan fácil. Aquí te contamos un poco de lo que tenemos para ti.",
    imageDesktop: Img.v1d,
    imageMobile: Img.v1m,
  },
  {
    title: "Academia 2.0",
    description:
      "Hemos dividido nuestra academia en tres grandes categorías: Sesiones en Vivo, Programas Online y Entrenamientos con Guía. Encuéntralos todos haciendo scroll en nuestra página de Academia o filtrando por las categorías más importantes para ti.",
    imageDesktop: Img.v2d,
    imageMobile: Img.v2m,
  },
  {
    title: "Sesiones en Vivo",
    description:
      "Cientos de profesores están online ahora mismo enseñando y compartiendo con nuestra comunidad. Cada clase es única y se dicta en vivo a horas programadas. Entra y participa haciendo preguntas a nuestros mentores y compañeros.",
    imageDesktop: Img.v3d,
    imageMobile: Img.v3m,
  },
  {
    title: "Entrenamientos con Guía",
    description:
      "Los entrenamientos con guía son bootcamps y seminarios especiales con instructores y un grupo reducido de personas. Están diseñados para sacar el mayor provecho de tus profesores a través de sesiones y grupos exclusivos.",
    imageDesktop: Img.v4d,
    imageMobile: Img.v4m,
  },
  {
    title: "Programas Online",
    description:
      "Nuestros Diplomados, Cursos y Especializaciones son el mejor recurso para aprender desde cero y adquirir conocimiento y confianza en el mundo del ecommerce. Todos nuestros programas son grabados con altísima calidad y los puedes realizar a tu propio ritmo.",
    imageDesktop: Img.v5d,
    imageMobile: Img.v5m,
  },
  {
    title: "Foro y Comunidad",
    description:
      "El área de comunidad incluye foros, eventos y grupos donde puedes conectar con tus compañeros para motivarnos, ayudarnos y aprender juntos.",
    imageDesktop: Img.v6d,
    imageMobile: Img.v6m,
  },
  {
    title: "Productos",
    description:
      "¿Listo para abrir las puertas? Nuestra sección de Productos te ayuda a investigar los mejores artículos para vender. Cuando estés listo, nuestro agente en China estará disponible para ayudarte a comprar, importar y empezar a vender tus productos.",
    imageDesktop: Img.v7d,
    imageMobile: Img.v7m,
  },
];
