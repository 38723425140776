import { FaqsAccordion } from "@/components/Bootcamps/Accordion/AccordionFaqs/accordionFaqs";
import { AccordionFaqs } from "@/components/Bootcamps/Accordion/utils";
import { Box, Heading, Text } from "@chakra-ui/react";
import { Trans } from "react-i18next";

export const FrequentQuestions = () => {
  const mockFaqs: AccordionFaqs = {
    data: [
      {
        question: "¿Necesito tener conocimientos previos para iniciar?",
        answer:
          "No, no necesitas conocimientos previos para empezar. Los programas de smartBeemo están diseñados tanto para principiantes como para emprendedores y profesionales con experiencia. Contamos con metodologías estructuradas que te guían paso a paso desde lo básico hasta estrategias avanzadas en ecommerce, marketing digital y ventas.",
      },
      {
        question: "¿En cuánto tiempo veré resultados?",
        answer:
          "Los resultados dependen de tu nivel de compromiso y aplicación de los conocimientos adquiridos. Sin embargo, muchos estudiantes comienzan a ver cambios significativos en sus negocios en pocos meses. Gracias al enfoque práctico de nuestros diplomados, especializaciones y cursos avanzados, puedes implementar lo aprendido desde el día uno.",
      },
      {
        question: "¿Cómo es la modalidad de estudio de smartBeemo?",
        answer:
          "smartBeemo ofrece una modalidad 100% online, con contenido en video pregrabado para que estudies a tu propio ritmo. Además, algunos programas incluyen sesiones en vivo, mentorías, acceso a comunidades privadas y recursos descargables. También contamos con bootcamps intensivos para aprendizajes acelerados y programas de acompañamiento con expertos.",
      },
      {
        question:
          "¿Qué hace diferente a smartBeemo? ¿Cuál es su oferta de valor?",
        answer:
          "smartBeemo se diferencia por su enfoque práctico, basado en la experiencia real de emprendedores y expertos en ecommerce y marketing digital. Nuestra oferta de valor se centra en:",
        listItems: [
          "Metodologías probadas aplicadas por profesionales en el campo.",
          "Acompañamiento con mentores y comunidad activa para resolver dudas y compartir experiencias.",
          "Programas estructurados para generar resultados tangibles, desde principiantes hasta avanzados.",
          "Acceso a contenido exclusivo y actualizaciones constantes, adaptándonos a las tendencias del mercado.",
        ],
      },
    ],
  };
  return (
    <Box maxW={"662px"} mx={"auto"} pt={{ base: 6, md: 10 }}>
      <Heading size={"md"} textAlign={"center"}>
        <Trans>frequent questions</Trans>
      </Heading>
      <Text fontSize={"sm"} textAlign={"center"} pt={2} pb={6}>
        <Trans>
          Resolve your questions with clear and quick answers about our services
          and processes.
        </Trans>
      </Text>
      <FaqsAccordion accordionContent={mockFaqs} />
    </Box>
  );
};
