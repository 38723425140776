import v1d from "./v1d 1.svg";
import v1m from "./v1m 1.svg";
import v2d from "./v2d 1.svg";
import v2m from "./v2m 1.svg";
import v3d from "./v3d 1.svg";
import v3m from "./v3m 1.svg";
import v4d from "./v4d 1.svg";
import v4m from "./v4m 1.svg";
import v5d from "./v5d 1.svg";
import v5m from "./v5m 1.svg";
import v6d from "./v6d 1.svg";
import v6m from "./v6m 1.svg";
import v7d from "./v7d 1.svg";
import v7m from "./v7m 1.svg";

type SvgrImg = React.FC<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
>;

/**
 * @param {string} [props.color="inherit"] Icon's color
 * @param {string} [props.fontSize="1em"] Icon's size
 * @param {string} [props.title] Icon's title for accesibility
 * @returns {SvgrIcon}
 */

const Img = {
  v1d,
  v1m,
  v2d,
  v2m,
  v3d,
  v3m,
  v4d,
  v4m,
  v5d,
  v5m,
  v6d,
  v6m,
  v7d,
  v7m,
};

export type { SvgrImg };
export { Img };
