/* eslint-disable @typescript-eslint/no-shadow */
import { LiveSession, Program } from "@/schemaTypes";
import { createContext, useEffect, useState } from "react";
import {
  LearnContext,
  Bootcamps,
  BrandData,
  LearnParams,
  LiveSessions,
} from "./types";
import { useParams } from "react-router-dom";
import { brandCard } from "../brandFilter/types";
import { mainBrands } from "../brandFilter/data";
import {
  filterBootcampsByCategory,
  filterByCategory,
  useQueryProgramsData,
  useQuerySessions,
} from "./utils";
import { useQueryBootcampsData } from "@/pages/GuideTraining/utils";

// eslint-disable-next-line react-refresh/only-export-components
export const learnContext = createContext<LearnContext>({} as LearnContext);

export const LearnProvider = ({ children }: { children: React.ReactNode }) => {
  const { subroute: selectedSubpage } = useParams<LearnParams>();
  const [selectedBrand, setSelectedBrand] = useState<brandCard | undefined>();
  const [brandData, setBrandData] = useState<BrandData>();

  const {
    sessions,
    scheduledSessions,
    loading: allSessionsLoading,
    refetch: refetchAllSessions,
  } = useQuerySessions();

  const [allSessions, setAllSessions] = useState<LiveSessions>({
    next: [],
    scheduled: [],
  });
  const [liveSessions, setLiveSessions] = useState<LiveSessions>({
    next: [],
    scheduled: [],
  });

  useEffect(() => {
    setAllSessions({
      next: sessions as LiveSession[],
      scheduled: scheduledSessions as LiveSession[],
    });
    setLiveSessions({
      next: sessions as LiveSession[],
      scheduled: scheduledSessions as LiveSession[],
    });
  }, [sessions]);

  useEffect(() => {
    if (allSessionsLoading) return;
    let brandFilter: string | undefined = undefined;
    if (selectedSubpage) {
      brandFilter = selectedSubpage;
    } else if (selectedBrand?.name) {
      brandFilter = selectedBrand.name;
    }
    if (!brandFilter) {
      setLiveSessions({
        next: sessions as LiveSession[],
        scheduled: scheduledSessions as LiveSession[],
      });
      return;
    }
    const cleanBrand = brandFilter.toLowerCase().replace(/-/g, " ");

    const filteredSessions = {
      next: filterByCategory(sessions as LiveSession[], "brand", cleanBrand),
      scheduled: filterByCategory(
        scheduledSessions as LiveSession[],
        "brand",
        cleanBrand
      ),
    };

    setLiveSessions(filteredSessions);
    setBrandData((prevState) => ({
      ...prevState,
      liveSessions: filteredSessions,
    }));
  }, [
    allSessionsLoading,
    selectedSubpage,
    selectedBrand,
  ]);

  const {
    data: allBootcamps,
    isDataLoading: bootcampsLoading,
    refetchBootcamps,
  } = useQueryBootcampsData();

  const [bootcamps, setBootcamps] = useState<Bootcamps>({
    completed: [],
    cta: [],
    myBootcamps: [],
  });

  useEffect(() => {
    if (bootcampsLoading) return;

    if (!selectedSubpage) return setBootcamps(allBootcamps);

    const cleanSubpage = selectedSubpage?.toLowerCase().replace(/-/g, " ");

    const filteredBootcamps = {
      cta: filterBootcampsByCategory(
        allBootcamps.cta.map((bc) => bc),
        "brand",
        cleanSubpage
      ),
      myBootcamps: filterBootcampsByCategory(
        allBootcamps.myBootcamps.map((bc) => bc),
        "brand",
        cleanSubpage
      ),
      completed: filterBootcampsByCategory(
        allBootcamps.completed.map((bc) => bc),
        "brand",
        cleanSubpage
      ),
    };

    setBootcamps(filteredBootcamps);
    setBrandData((prevState) => ({
      ...prevState,
      bootcamps: filteredBootcamps,
    }));
  }, [bootcampsLoading, selectedSubpage]);

  const { allPrograms, getProgramsLoading } = useQueryProgramsData();

  const [programs, setPrograms] = useState<Program[]>([]);

  useEffect(() => {
    if (getProgramsLoading) return;

    if (!selectedSubpage) return setPrograms(allPrograms as Program[]);

    const cleanSubpage = selectedSubpage?.toLowerCase().replace(/-/g, " ");

    const filteredPrograms = filterByCategory(
      allPrograms as Program[],
      "brand",
      cleanSubpage
    );

    setPrograms(filteredPrograms as Program[]);
    setBrandData((prevState) => ({
      ...prevState,
      programs: filteredPrograms,
    }));
  }, [getProgramsLoading, selectedSubpage]);

  const [selectedSubCategory, setSelectedSubCategory] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (selectedSubpage) {
      const cleanSubpage = selectedSubpage?.toLowerCase().replace(/-/g, " ");
      const selectedBrand = mainBrands.find(
        (brand) => brand.name.toLowerCase() === cleanSubpage
      );
      setSelectedBrand(selectedBrand);
    }
    setSelectedSubCategory(undefined);
  }, [selectedSubpage]);

  useEffect(() => {
    const cleanSubCategory = selectedSubCategory
      ?.toLowerCase()
      .replace(/-/g, " ");
    if (!cleanSubCategory && !selectedBrand) {
      setBootcamps(allBootcamps);
      setPrograms(allPrograms as Program[]);
      setLiveSessions(allSessions);
      return;
    }
    const {
      bootcamps: brandBootcamps,
      liveSessions: brandLiveSessions,
      programs: brandPrograms,
    } = brandData || {};

    if (!cleanSubCategory && selectedBrand) {
      setBootcamps(brandBootcamps as Bootcamps);
      setPrograms(brandPrograms as Program[]);
      setLiveSessions(brandLiveSessions as LiveSessions);
      return;
    }

    const filteredBootcamps = {
      cta: filterBootcampsByCategory(
        brandData?.bootcamps?.cta ?? [],
        "subCategory",
        cleanSubCategory as string
      ),
      myBootcamps: filterBootcampsByCategory(
        brandData?.bootcamps?.myBootcamps ?? [],
        "subCategory",
        cleanSubCategory as string
      ),
      completed: filterBootcampsByCategory(
        brandData?.bootcamps?.completed ?? [],
        "subCategory",
        cleanSubCategory as string
      ),
    };

    const filteredPrograms = filterByCategory(
      brandData?.programs as Program[],
      "subCategory",
      cleanSubCategory as string
    );

    const filteredSessions = {
      next: filterByCategory(
        brandData?.liveSessions?.next ?? [],
        "subCategory",
        cleanSubCategory as string
      ),
      scheduled: filterByCategory(
        brandData?.liveSessions?.scheduled ?? [],
        "subCategory",
        cleanSubCategory as string
      ),
    };

    setBootcamps(filteredBootcamps);
    setPrograms(filteredPrograms);
    setLiveSessions(filteredSessions);
  }, [selectedSubCategory]);

  return (
    <learnContext.Provider
      value={{
        brandData,
        setBrandData,
        selectedBrand,
        selectedSubpage: selectedSubpage,
        selectedSubCategory,
        setSelectedSubCategory,
        programs,
        programsLoading: getProgramsLoading,
        setPrograms,
        bootcamps,
        setSelectedBrand,
        setBootcamps,
        bootcampsLoading,
        refetchBootcamps,
        allSessions: allSessions,
        liveSessions,
        setLiveSessions,
        allSessionsLoading,
        refetchAllSessions,
      }}
    >
      {children}
    </learnContext.Provider>
  );
};
