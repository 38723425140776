import { Box, Heading, Text, Stack, List, ListItem } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const PrivacyPolicies = () => {
  const { t } = useTranslation();

  return (
    <Box maxW="912px" mx="auto" py={10} px={4}>
      <Heading as="h1" size="xl" textAlign="center" mb="4">
        {t("privacyPolicyTranslations.header.Privacy Policy")}
      </Heading>
      <Text textAlign="center" fontSize="sm" color="gray.600" mb="8">
        {t("privacyPolicyTranslations.header.August 10, 2021")}
      </Text>

      <Stack spacing="6">
        <Box>
          <Heading as="h2" size="md" mb="2">
            {t("privacyPolicyTranslations.sections.cookies.Cookies")}
          </Heading>
          <Text fontSize="sm">
            {t(
              "privacyPolicyTranslations.sections.cookies.SmartBeemo uses cookies to ensure you get the best experience on our website. By continuing to browse this site, you are accepting the use of cookies. For more information, visit our Privacy Policy and Personal Data Treatment Policy."
            )}
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb="2" textAlign="center">
            {t(
              "privacyPolicyTranslations.sections.introduction.Privacy and Personal Data Treatment Policy for SmartBeemo"
            )}
          </Heading>
          <Heading as="h3" size="sm" mb="2">
            {t(
              "privacyPolicyTranslations.sections.introduction.1. Introduction"
            )}
          </Heading>
          <Text fontSize="sm">
            {t(
              "privacyPolicyTranslations.sections.introduction.SMART BEEMO, LLC (hereinafter, smartBeemo), dedicated to online education, headquartered in the United States (hereinafter the 'Company'), offers you the opportunity to study through our online services as well as downloads that may be available on our website. smartBeemo recognizes and respects the privacy, security, and confidentiality of the personal data of its collaborators, affiliates, clients, employees, and all its stakeholders (hereinafter the 'Users') whose personal information it processes. Therefore, this Privacy Policy and Personal Data Treatment Policy (hereinafter the 'Treatment Policy') has been created to communicate how the information collected and/or managed by the Company will be used."
            )}
            <br />
            <br />
            {t(
              "privacyPolicyTranslations.sections.introduction.The scope of this Treatment Policy also includes subsidiary web pages owned by smartBeemo. On each of these websites, you will find this Treatment Policy published."
            )}
          </Text>
        </Box>

        <Box>
          <Heading as="h3" size="sm" mb="2">
            {t(
              "privacyPolicyTranslations.sections.purpose.2. Why do we process your data?"
            )}
          </Heading>
          <Text fontSize="sm">
            {t(
              "privacyPolicyTranslations.sections.purpose.For smartBeemo, maintaining a permanent relationship with its Users is essential and therefore, the personal data we collect is used to establish communication ties and work on the constant improvement of our service portfolio. For this reason, your personal data will be processed for one or more of the following purposes"
            )}
          </Text>
          <br />
          <List fontSize="sm" pl="6" styleType="disc">
            <ListItem>
              {t(
                "privacyPolicyTranslations.sections.purpose.Sending commercial communications via email to inform about news, promotions, products, and services of smartBeemo. If any news from a third party is communicated, they will never have access to personal data."
              )}
            </ListItem>
            <ListItem>
              {t(
                "privacyPolicyTranslations.sections.purpose.Conducting anonymous market studies."
              )}
            </ListItem>
            <ListItem>
              {t(
                "privacyPolicyTranslations.sections.purpose.Processing orders, requests, responding to queries or any type of petition made by users through any of the contact forms provided by smartBeemo, such as the website, email, forums, calls, events, invitations, among others."
              )}
            </ListItem>
            <ListItem>
              {t(
                "privacyPolicyTranslations.sections.purpose.Providing support to Users in any stage of their use of smartBeemo's services."
              )}
            </ListItem>
          </List>
        </Box>

        <Box>
          <Heading as="h3" size="sm" mb="2">
            {t(
              "privacyPolicyTranslations.sections.dataCollection.3. What information do we collect?"
            )}
          </Heading>
          <List fontSize="sm" pl="4" styleType="disc">
            <ListItem>
              {t(
                "privacyPolicyTranslations.sections.dataCollection.Registration Information. You are not required to provide any personal information to access our website or view the information we provide. However, we may offer you the opportunity to register with us or join our mailing list. While registration is not necessary to access our website, it is required to create an account with smartBeemo to access our materials and content resources, during which you agree to provide personal information. For smartBeemo, this information is valuable to better understand the User, advise them, and provide appropriate services to meet their study needs."
              )}
            </ListItem>
            <ListItem>
              {t(
                "privacyPolicyTranslations.sections.dataCollection.Automatic Data Collection. You may visit smartBeemo's website and use some of our services anonymously. Whether you register with us or not, smartBeemo may collect certain personal information, such as the type of Internet browser you use, your IP address, connection speed, your Internet service provider's domain name, your geographical location while accessing our services, and/or the webpage or ad from which you connected to the website."
              )}
            </ListItem>
            <ListItem>
              {t(
                "privacyPolicyTranslations.sections.dataCollection.Cookies. When you visit our website, click on any hyperlink appearing on it, or use one or more of smartBeemo's services, a standard industry technology called 'cookies' is used to store certain information on your computer, allowing us to personalize your experience to align it with your interests and preferences or simply facilitate your access while using our services."
              )}
            </ListItem>
          </List>
          <br />
          <Text fontSize="sm">
            {t(
              "privacyPolicyTranslations.sections.dataCollection.Users, by checking the corresponding boxes and entering data in the fields marked with an asterisk (*) in the contact form or presented in download forms, expressly, freely, and unequivocally accept that their data is necessary to fulfill their request by the provider, with the inclusion of data in the remaining fields being voluntary. The User guarantees that the personal data provided is truthful and is responsible for communicating any modifications to it."
            )}
          </Text>
        </Box>

        <Box>
          <Heading as="h3" size="sm" mb="2">
            {t(
              "privacyPolicyTranslations.sections.dataSecurity.4. Security of personal data"
            )}
          </Heading>
          <Text fontSize="sm">
            {t(
              "privacyPolicyTranslations.sections.dataSecurity.Users' data will be retained no longer than necessary to fulfill the purpose of processing or as required by legal regulations that determine their custody. Once they are no longer necessary, they will be deleted using appropriate security measures to ensure data anonymization or complete destruction. smartBeemo has a reasonable technical protection system to safeguard the personal data collected. smartBeemo guarantees the user that their personal data will be processed in accordance with this policy and will make every effort to mitigate any risks related to unauthorized access to its servers, a situation inherent to any security system."
            )}
          </Text>
          <br />
          <Text fontSize="sm">
            {t(
              "privacyPolicyTranslations.sections.dataSecurity.Similarly, access to Users' information by smartBeemo's collaborators and affiliates is protected by filters and security levels that ensure restricted access."
            )}
          </Text>
          <br />
          <Text fontSize="sm">
            {t(
              "privacyPolicyTranslations.sections.dataSecurity.Users' personal data is only used for smartBeemo's operations, except when shared with third-party providers and/or service partners related to communications, hosting, IT support, among others, to ensure proper service delivery. In such cases, the transfer of personal data will be carried out in strict compliance with this Data Treatment Policy and the security standards implemented by the Company."
            )}
          </Text>
        </Box>

        <Box>
          <Heading as="h3" size="sm" mb="2">
            {t(
              "privacyPolicyTranslations.sections.userRights.5. Users' rights"
            )}
          </Heading>
          <Text fontSize="sm">
            {t(
              "privacyPolicyTranslations.sections.userRights.The rights granted to Users regarding their personal data include"
            )}
          </Text>
          <List fontSize="sm" pl="4" styleType="disc">
            <ListItem>
              {t(
                "privacyPolicyTranslations.sections.userRights.To know, update, and rectify their personal data being processed by the Company or the data processors."
              )}
            </ListItem>
            <ListItem>
              {t(
                "privacyPolicyTranslations.sections.userRights.To request proof of the authorization granted to the Company, except when explicitly exempted as a requirement for processing."
              )}
            </ListItem>
            <ListItem>
              {t(
                "privacyPolicyTranslations.sections.userRights.To revoke authorization and/or request data deletion when the processing does not respect constitutional and legal principles, rights, and guarantees, at any time."
              )}
            </ListItem>
            <ListItem>
              {t(
                "privacyPolicyTranslations.sections.userRights.To know smartBeemo's Privacy Policy and any substantial changes to it."
              )}
            </ListItem>
            <ListItem>
              {t(
                "privacyPolicyTranslations.sections.userRights.Others granted by the applicable legal regulations."
              )}
            </ListItem>
            <ListItem>
              {t(
                "privacyPolicyTranslations.sections.userRights.At any time, users may contact us to exercise any of their data protection rights."
              )}
            </ListItem>
            <ListItem>
              {t(
                "privacyPolicyTranslations.sections.userRights.To exercise your rights, you must send an email to soporte@smartbeemo.com expressing your request. This email must match the one we have registered in our database."
              )}
            </ListItem>
          </List>
        </Box>

        <Box>
          <Heading as="h3" size="sm" mb="2">
            {t(
              "privacyPolicyTranslations.sections.inquiries.6. Questions, complaints, and claims"
            )}
          </Heading>
          <Text fontSize="sm">
            {t(
              "privacyPolicyTranslations.sections.inquiries.smartBeemo has the following means available to address questions, complaints, and claims submitted by users"
            )}
          </Text>
          <Text fontSize="sm" fontWeight="bold">
            {t(
              "privacyPolicyTranslations.sections.inquiries.Email soporte@smartbeemo.com"
            )}
          </Text>
        </Box>

        <Box>
          <Heading as="h3" size="sm" mb="2">
            {t(
              "privacyPolicyTranslations.sections.policyChanges.7. Policy changes"
            )}
          </Heading>
          <Text fontSize="sm">
            {t(
              "privacyPolicyTranslations.sections.policyChanges.smartBeemo reserves the right to modify this Treatment Policy at any time without prior notice to Users. However, any changes or modifications will be communicated to users through the contact channels previously established by smartBeemo and will be available for consultation on smartBeemo's websites. If the User disagrees with the new Treatment Policy, they may request withdrawal through the channel provided above. However, it is clarified that withdrawal or deletion of personal data cannot be requested while there is any kind of ongoing relationship with the Company."
            )}
          </Text>
        </Box>

        <Box>
          <Heading as="h3" size="sm" mb="2">
            {t("privacyPolicyTranslations.sections.validity.8. Validity")}
          </Heading>
          <Text fontSize="sm">
            {t(
              "privacyPolicyTranslations.sections.validity.This policy has been effective since August 10, 2021."
            )}
          </Text>
        </Box>
      </Stack>
    </Box>
  );
};
