/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Language, normalizeLanguage } from "../locales/language";
import { TranslatedField } from "../schemaTypes";

export type TranslateFieldFn = (
  field?: Array<TranslatedField> | null,
  languageOverride?: string,
  useFallbackValue?: boolean
) => string | undefined;

export const useTranslatedField = (): TranslateFieldFn => {
  const { i18n, t } = useTranslation();
  const { language: currentLanguage } = i18n;

  const translateField = useCallback<TranslateFieldFn>(
    (field, languageOverride, useFallbackValue = true) => {
      const language = languageOverride ?? normalizeLanguage(currentLanguage);

      return field !== undefined && field !== null
        ? useFallbackValue
          ? (field.find(({ locale }) => locale === language)?.value ??
              field.find(({ locale }) => locale === Language.EN)?.value) ||
            ` -- ${t("Not available yet in your current language")} --`
          : field.find(({ locale }) => locale === language)?.value
        : undefined;
    },
    [currentLanguage]
  );

  return translateField;
};
