import React from "react";
import { Box, Flex, IconButton, Tag, Text } from "@chakra-ui/react";
import { LiveSession, LiveSessionType } from "@/schemaTypes";
import { Ico } from "@/assets/icons";
import LivePreview from "@/components/LiveSessionsZoom/LivePreview/LivePreview";
import { parseLiveSessionType } from "./utils";
import { Trans } from "react-i18next";
import { Button } from "@/components/Button";
import { fontWeight } from "@/components/Theme/Text";

interface PreviewCardProps {
  sessionSelectedStarted?: LiveSession;
  enterToSession: () => void;
}

const PreviewCard: React.FC<PreviewCardProps> = (props) => {
  const { sessionSelectedStarted, enterToSession } = props;
  const liveSessionId = sessionSelectedStarted?.id;

  return (
    <Flex
      key={`preview-${liveSessionId}`}
      userSelect="none"
      borderRadius="8px"
      width="100%"
      flexDirection="column"
      justifyContent="flex-end"
      overflow="hidden"
      alignSelf="stretch"
      position="relative"
    >
      <Box
        position="relative"
        w="100%"
        flexGrow={0}
        height="200px"
        backgroundRepeat="no-repeat"
        borderRadius="8px"
        backgroundSize="100%"
        backgroundPosition="center"
        backgroundImage="/img/cv-started.webp"
        _hover={{
          ".play-button": {
            opacity: 1,
          },
          "&::after": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "#1E1E23",
            opacity: 0.6,
            mixBlendMode: "darken",
            borderRadius: "8px",
            zIndex: 1,
          },
        }}
      >
        <Tag
          pos="absolute"
          top="8px"
          left="8px"
          bg="error.300"
          color="#fff"
          zIndex="1"
          h="16px"
          borderRadius="6px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          pointerEvents="none"
        >
          <Text
            fontWeight={fontWeight.bold}
            fontSize="10px"
            textAlign="center"
            py="0"
          >
            EN VIVO
          </Text>
        </Tag>
        <IconButton
          className="play-button"
          aria-label="Play"
          rounded="full"
          bg="primary.300"
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          zIndex="2"
          opacity="0"
          transition="opacity 0.2s ease-in-out"
          h="32px"
          w="32px"
          onClick={() => enterToSession()}
          _hover={{ bg: "primary.300" }}
        >
          <Box ml="2px">
            <Ico.PlayFill />
          </Box>
        </IconButton>
        {liveSessionId && (
          <Box
            key={`preview-container-${liveSessionId}`}
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
          >
            <LivePreview
              key={`live-preview-${liveSessionId}`}
              liveSessionId={liveSessionId}
            />
          </Box>
        )}
      </Box>

      <Flex
        w="100%"
        flexGrow={1}
        alignItems="flex-start"
        justify="space-between"
        flexDir="column"
        gap="16px"
      >
        <Box>
          <Text
            fontWeight={700}
            mt="12px"
            variant="caption"
            textTransform="uppercase"
            fontFamily="Poppins"
          >
            <Trans>
              {parseLiveSessionType(
                sessionSelectedStarted?.liveSessionType ||
                  LiveSessionType.Livesession
              )}
            </Trans>
          </Text>
          <Text fontWeight={600} variant="caption">
            {sessionSelectedStarted?.name}
          </Text>
        </Box>

        <Button
          py={0}
          variant="link"
          onClick={() => enterToSession()}
          rightIcon={<Ico.ArrowRight width="18px" height="18px" />}
          fontSize="14px"
          lineHeight={1}
          height="22px"
        >
          <Trans>Enter Live Class</Trans>
        </Button>
      </Flex>
    </Flex>
  );
};

export default PreviewCard;
