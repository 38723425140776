import { AppRoute, PublicRoute } from "@/AppRoute";
import { EntityName } from "@/schemaTypes";
import { createContext } from "react";

type NavigationContextValue = {
  redirectRoute: AppRoute | PublicRoute | null;
  updateRedirectRoute: (route: AppRoute | PublicRoute | null) => void;
  productOfInterest: string | null;
  productTypeOfInterest: EntityName | string | null;
  updateProductOfInterest: (
    productTypeOfInterest: EntityName | string | null,
    productOfInterest: string | null
  ) => void;
  clearProductOfInterest: () => void;
  getProductOfInterestFromStorage: () => {
    productTypeOfInterest: EntityName | null;
    productOfInterest: string | null;
  };
};
interface NavigationContextState {
  redirectRoute?: AppRoute | PublicRoute | null;
  updateRedirectRoute: (route: AppRoute | PublicRoute | null) => void;
  productOfInterest?: string | null;
  productTypeOfInterest?: EntityName | string | null;
  updateProductOfInterest: (
    productTypeOfInterest: EntityName | string | null,
    productOfInterest: string | null
  ) => void;
  clearProductOfInterest: () => void;
  getProductOfInterestFromStorage: () => {
    productTypeOfInterest: EntityName | null | string;
    productOfInterest: string | null;
  };
}

const NavigationContext = createContext<NavigationContextState | null>(null);

export type { NavigationContextState, NavigationContextValue };
export { NavigationContext };
