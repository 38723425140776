import { AppRoute } from "@/AppRoute";
import { Ico } from "@/assets/icons";
import { brandCard } from "./types";

const description = "Academia";

export const mainBrands: brandCard[] = [
  {
    name: "Amazon",
    description: description,
    icon: <Ico.Amazon fontSize={"48px"} />,
    cleanIcon: <Ico.CleanAmazon fontSize={"48px"} />,
    route: AppRoute.Amazon,
  },
  {
    name: "Dropshipping",
    description: description,
    icon: <Ico.Dropshipping fontSize={"48px"} />,
    cleanIcon: <Ico.CleanDropshipping fontSize={"48px"} />,
    route: AppRoute.Dropshipping,
  },
  {
    name: "Mercado Libre",
    description: description,
    icon: <Ico.Meli fontSize={"48px"} />,
    cleanIcon: <Ico.CleanMeli fontSize={"48px"} />,
    route: AppRoute.Meli,
  },
  {
    name: "Full Commerce",
    description: description,
    icon: <Ico.FullComerce fontSize={"48px"} />,
    cleanIcon: <Ico.CleanFullComerce fontSize={"48px"} />,
    route: AppRoute.FullComerce,
  },
];
