export const tableAccessPlansData = [
  {
    category: "Academia",
    items: [
      { name: "Diplomados", basic: true, pro: true, premium: true },
      { name: "Especializaciones", basic: true, pro: true, premium: true },
      { name: "Cursos Avanzados", basic: true, pro: true, premium: true },
      { name: "Clases en Vivo", basic: true, pro: true, premium: true },
      { name: "Masterclasses", basic: true, pro: true, premium: true },
      { name: "Talleres de inicio", basic: true, pro: true, premium: true },
      {
        name: "Bootcamps (Entrenamiento con Guía)",
        basic: false,
        pro: false,
        premium: true,
      },
    ],
  },
  {
    category: "Acompañamiento",
    items: [
      { name: "Chat con Tutores", basic: true, pro: true, premium: true },
      {
        name: "Acceso a Agente de Éxito",
        basic: true,
        pro: true,
        premium: true,
      },
    ],
  },
  {
    category: "Comunidad",
    items: [
      {
        name: "Acceso a eventos presenciales",
        basic: true,
        pro: true,
        premium: true,
      },
      {
        name: "Acceso a comunidades de Whatsapp",
        basic: true,
        pro: true,
        premium: true,
      },
    ],
  },
  {
    category: "Producto",
    items: [
      {
        name: "Acceso Agente en China",
        basic: false,
        pro: true,
        premium: true,
      },
      {
        name: "Recomendaciones de Productos",
        basic: false,
        pro: true,
        premium: true,
      },
    ],
  },
];
