import { colors } from "@/components/Theme/colors";
import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const filter = definePartsStyle({
  container: defineStyle({
    px: 3,
    py: "8px",
    _hover: {
      bgColor: "transparent",
      cursor: "pointer",
      ".chakra-checkbox__control:not([data-checked])": {
        borderColor: "inherit",
      },
    },
  }),
  control: defineStyle({
    rounded: "4px",
    borderColor: "inherit",

    _focusVisible: {
      outline: "none",
      boxShadow: "none",
    },
    _checked: {
      borderColor: colors.secondary[500],
      bgColor: colors.secondary[500],
    },
  }),
  label: defineStyle({
    fontSize: "14px",
    marginLeft: "8x",
  }),
  icon: defineStyle({
    fontSize: "16px",
  }),
});

const primary = definePartsStyle({
  container: defineStyle({
    _hover: {
      bgColor: "transparent",
      cursor: "pointer",
      ".chakra-checkbox__control:not([data-checked])": {
        borderColor: "inherit",
      },
    },
  }),
  control: defineStyle({
    rounded: "4px",
    borderColor: "inherit",
    _focusVisible: {
      outline: "none",
      boxShadow: "none",
    },
    _checked: {
      borderColor: colors.primary[300],
      bgColor: colors.primary[300],
    },
  }),
  label: defineStyle({
    fontSize: "14px",
    marginLeft: "8x",
  }),
  icon: defineStyle({
    fontSize: "16px",
  }),
});

export const checkboxTheme = defineMultiStyleConfig({
  variants: { filter, primary },
});
