import { useContext } from "react";

import { NavigationContext, NavigationContextState } from "./Context";

const useCustomNavigation = (): NavigationContextState => {
  const ctx = useContext(NavigationContext);
  if (ctx === null) {
    throw Error("NavigationContext context provider not found");
  }
  return ctx;
};

export { useCustomNavigation };
