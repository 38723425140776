import { Flex, Image, Box, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import { SignUpCard } from "@/components/SignUpCard";

const SignUpPage: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  return (
    <Flex bgColor={"white"} h={"100vh"}>
      {!isMobile && (
        <Flex
          w={"100%"}
          aspectRatio={1.025}
          overflow={"hidden"}
          pos={"relative"}
        >
          <Image
            boxSize={"100%"}
            fit={"cover"}
            src={
              "https://storage.googleapis.com/lms-stg-media/bienvenida/unnamed.jpg"
            }
          />
          <Box
            bgColor={"primary.500"}
            bottom={"50%"}
            boxSize={"65%"}
            filter={"blur(23vh)"}
            left={"50%"}
            pos={"absolute"}
          />
          <Box
            bgColor={"tertiary.300"}
            boxSize={"81%"}
            filter={"blur(14vh)"}
            pos={"absolute"}
            right={"75%"}
            top={"75%"}
          />
        </Flex>
      )}
      <SignUpCard />
    </Flex>
  );
};

export default SignUpPage;
