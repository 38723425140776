import {
  Box,
  Flex,
  Heading,
  Link,
  List,
  ListIcon,
  ListItem,
  Text,
  Thead,
  Tr,
  Th,
  Td,
  Table,
  UnorderedList,
  TableContainer,
  Tbody,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { PageContainer } from "@/components/PageContainer";
import { Ico } from "@/assets/icons";
import {
  UserBehavior,
  Academy,
  Accompaniment,
  Community,
  Product,
  ChargesAndPayments,
  RecurringSubscriptions,
  Warranties,
  CopyrightComplaints,
  MarketplaceTerms,
  PermanenceConditions,
  Equivalencies,
  ContentScope,
} from "./TermsConditionInterfaces";
import { tableAccessPlansData } from "./plansTableData";

const TermsAndConditionsPage: React.FC = () => {
  const { t } = useTranslation();
  const userBehavior: UserBehavior = {
    sections: t("userBehavior.sections", {
      returnObjects: true,
    }),
  };

  const academy: Academy = t("academy", { returnObjects: true });

  const accompaniment: Accompaniment = t("accompaniment", {
    returnObjects: true,
  });

  const community: Community = t("community", { returnObjects: true });

  const product: Product = t("product", { returnObjects: true });

  const chargesAndPayments: ChargesAndPayments = t("chargesAndPayments", {
    returnObjects: true,
  });

  const recurringSubscriptions: RecurringSubscriptions = t(
    "recurringSubscriptions",
    { returnObjects: true }
  );

  const warranties: Warranties = t("warranties", { returnObjects: true });
  const permanenceConditions: PermanenceConditions = t("permanenceClause", {
    returnObjects: true,
  });
  const equivalencies: Equivalencies = t("equivalencies", {
    returnObjects: true,
  });
  const contentScope: ContentScope = t("contentScopeSuccessRelation", {
    returnObjects: true,
  });

  const partsWarranties = warranties.refundRequest.split("{{contactLink}}");

  const copyrightComplaints: CopyrightComplaints = t("copyrightComplaints", {
    returnObjects: true,
  });

  const partsIntroduction =
    copyrightComplaints.introduction.split("{{emailLink}}");

  const marketplaceTerms: MarketplaceTerms = t("marketplaceTerms", {
    returnObjects: true,
  });
  const partsMarketplaceTerms =
    marketplaceTerms.content.split("{{contentLink}}");

  return (
    <PageContainer
      overflow={"hidden"}
      display={"flex"}
      maxW={"1000px"}
      flexDir={"column"}
      alignItems={"center"}
      textAlign={"justify"}
    >
      <Heading as={"h4"} pt={4}>
        {t("Terms and Conditions")}
      </Heading>
      <Text fontSize={"small"} pt={1}>
        {t("termsTitle")}
      </Text>
      <Text pt={2}>{t("termsContent")}</Text>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("introTitle")}
      </Heading>
      <Heading pt={2} ml={4} alignSelf={"flex-start"}>
        {t("Parts")}
      </Heading>
      <Text mt={1} ml={4}>
        {t("introContent1")}
        <Link
          color={"primary.400"}
          href="https://smartbeemo.com"
          isExternal
          rel="noopener noreferrer"
        >
          smartbeemo.com
        </Link>{" "}
        {t("introContent2")}
      </Text>
      <Heading pt={2} ml={4} alignSelf={"flex-start"}>
        {t("modificationsTitle")}
      </Heading>
      <Text mt={1} ml={4}>
        {t("modificationsContent")}
      </Text>
      <Heading pt={2} ml={4} alignSelf={"flex-start"}>
        {t("privacyTitle")}
      </Heading>
      <Text mt={1} ml={4}>
        {t("privacyContent1")}
        <Link
          color={"primary.400"}
          href="https://smartbeemo.com/politica-de-privacidad/"
          isExternal
          rel="noopener noreferrer"
        >
          {t("privacyPolicy")}
        </Link>
        {t("privacyContent2")}
      </Text>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("accessAndUseTitle")}
      </Heading>
      <Heading pt={2} ml={4} alignSelf={"flex-start"}>
        {t("descriptionOfServiceUseTitle")}
      </Heading>
      <Text mt={1} ml={4}>
        {t("descriptionOfServiceUseContent1")}
      </Text>
      <Text mt={1} ml={4}>
        {t("descriptionOfServiceUseContent2")}
      </Text>
      <Heading pt={2} ml={4} alignSelf={"flex-start"}>
        {t("AvailabilityOfServices")}
      </Heading>
      <Text mt={1} ml={4}>
        {t("availabilityAndRegistrationContent1")}
        <Link
          color={"primary.400"}
          href="https://smartbeemo.com/politica-de-privacidad/"
          isExternal
          rel="noopener noreferrer"
        >
          {t("privacyPolicy")}
        </Link>
        {t("availabilityAndRegistrationContent2")}
      </Text>
      <Text mt={1} ml={4}>
        {t("availabilityAndRegistrationContent3")}
      </Text>
      <Heading pt={2} ml={4} alignSelf={"flex-start"}>
        {t("membershipAccountSecurityTitle")}
      </Heading>
      <Text mt={1} ml={4}>
        {t("membershipAccountSecurityContent")}
      </Text>
      <Heading pt={2} ml={4} alignSelf={"flex-start"}>
        {t("modificationsToProductsAndServicesTitle")}
      </Heading>
      <Text mt={1} ml={4}>
        {t("modificationsToProductsAndServicesContent")}
      </Text>
      <Heading pt={2} ml={4} alignSelf="flex-start">
        {t("userBehavior.title")}
      </Heading>
      {userBehavior.sections &&
        userBehavior.sections.map((section) => (
          <Box key={section.id} mt={1} ml={4}>
            <Text mb={2}>{section.content}</Text>
            <UnorderedList pt={2} pl={8}>
              {section.rules &&
                section.rules.map((rule, index) => (
                  <ListItem key={index}>{rule}</ListItem>
                ))}
            </UnorderedList>
          </Box>
        ))}
      <Text mt={1} ml={4}>
        {t("userBehavior.conclusion")}
      </Text>
      <Heading pt={2} ml={4} alignSelf={"flex-start"}>
        {t("prohibitionOfCommercialUseTitle")}
      </Heading>
      <Text mt={1} ml={4}>
        {t("prohibitionOfCommercialUseContent")}
      </Text>

      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("welcomeAccessPlansTitle")}
      </Heading>
      <Text pt={2} alignSelf={"flex-start"}>
        {t("monthlyPayment.contractNote")}
      </Text>

      <TableContainer width={"100%"} pt={4}>
        <Table border={"1px solid"} borderColor="gray.200" variant={"unstyled"}>
          <Thead>
            <Tr bgColor={"secondary.50"}>
              <Th colSpan={2} color={"white"}>
                Contenido
              </Th>
              <Th color={"white"}>Básico</Th>
              <Th color={"white"}>Pro</Th>
              <Th color={"white"}>Premium</Th>
            </Tr>
          </Thead>
          <Tbody>
            {tableAccessPlansData &&
              tableAccessPlansData.map((category, categoryIndex) => (
                <React.Fragment key={categoryIndex}>
                  {category.items &&
                    category.items.map((item, itemIndex) => (
                      <Tr
                        key={`${categoryIndex}-${itemIndex}`}
                        border={"1px solid"}
                        borderColor="gray.200"
                      >
                        {itemIndex === 0 && (
                          <Td
                            rowSpan={category.items.length}
                            verticalAlign="top"
                            borderRight="1px solid"
                            borderColor="gray.200"
                          >
                            <Text fontWeight="bold">{category.category}</Text>
                          </Td>
                        )}
                        <Td>{item.name}</Td>
                        <Td textAlign="center">
                          {item.basic && <Ico.SquareCheck />}
                        </Td>
                        <Td textAlign="center">
                          {item.pro && <Ico.SquareCheck />}
                        </Td>
                        <Td textAlign="center">
                          {item.premium && <Ico.SquareCheck />}
                        </Td>
                      </Tr>
                    ))}
                </React.Fragment>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Text pt={4}>{t("contentTermsDefinitionsContent")}</Text>
      <Heading pt={4} alignSelf={"flex-start"}>
        {t("academy.title")}
      </Heading>
      {academy.programs &&
        academy.programs.map((program, index) => (
          <Box key={index}>
            <UnorderedList pt={2} pl={4}>
              <ListItem pt={2}>
                <Heading>{program.name}</Heading>
              </ListItem>
            </UnorderedList>
            <Text mt={1} pl={8}>
              {program.description}
            </Text>

            {program.rules && (
              <Box mt={2}>
                <List>
                  {program.rules &&
                    program.rules.map((rule, ruleIndex) => (
                      <ListItem mt={1} ml={12} key={ruleIndex}>
                        <Flex flexDir={"column"}>
                          <Flex>
                            <ListIcon
                              pt={2}
                              w={"10px"}
                              as={Ico.Circle}
                              color="black"
                            />
                            <Text>
                              {rule.title}: {rule.description}
                            </Text>
                          </Flex>
                        </Flex>
                      </ListItem>
                    ))}
                </List>
              </Box>
            )}
          </Box>
        ))}

      <Heading pt={4} alignSelf={"flex-start"}>
        {t("accompaniment.title")}
      </Heading>
      {accompaniment.services &&
        accompaniment.services.map((service, index) => (
          <Box key={index}>
            <UnorderedList pl={8}>
              <ListItem pt={2}>
                <Text>{service.name}</Text>
              </ListItem>
            </UnorderedList>
            <Text mt={1} pl={12}>
              {service.description}
            </Text>

            {service.details && (
              <Box mt={2}>
                <List>
                  {service.details &&
                    service.details.map((detail, detailIndex) => (
                      <ListItem mt={1} ml={20} key={detailIndex}>
                        <Flex flexDir={"column"}>
                          <Flex>
                            <ListIcon
                              pt={2}
                              w={"10px"}
                              as={Ico.Circle}
                              color="black"
                            />
                            <Text>
                              {detail.title}: {detail.description}
                            </Text>
                          </Flex>
                        </Flex>
                      </ListItem>
                    ))}
                </List>
              </Box>
            )}
          </Box>
        ))}
      <Heading pt={4} alignSelf={"flex-start"}>
        {t("community.title")}
      </Heading>
      <Flex flexDir={"column"}>
        {community.features &&
          community.features.map((feature, index) => (
            <Box key={index}>
              <UnorderedList pt={2} pl={8}>
                <ListItem>
                  <Text>{feature.name}</Text>
                  <Text>{feature.description}</Text>
                </ListItem>
              </UnorderedList>
            </Box>
          ))}
      </Flex>
      <Heading pt={4} alignSelf={"flex-start"}>
        {t("product.title")}
      </Heading>
      {product.services &&
        product.services.map((service, index) => (
          <Box key={index}>
            <UnorderedList pt={2} pl={8}>
              <ListItem>
                <Text>{service.name}</Text>
                <Text>{service.description}</Text>
              </ListItem>
            </UnorderedList>
            {service.features && (
              <Box pl={12}>
                <Text pt={2}>{service.descriptionFeatures}</Text>
                <List pt={2}>
                  {service.features &&
                    service.features.map((feature, featureIndex) => (
                      <ListItem key={featureIndex}>
                        <Flex flexDir={"column"}>
                          <Flex>
                            <ListIcon
                              pt={2}
                              w={"10px"}
                              as={Ico.Circle}
                              color="black"
                            />
                            {feature}
                          </Flex>
                        </Flex>
                      </ListItem>
                    ))}
                </List>
              </Box>
            )}
          </Box>
        ))}
      <Heading as={"h5"} pt={4} alignSelf={"flex-start"}>
        {t("admissionFee.title")}
      </Heading>
      <Box>
        <Text pt={2}>{t("admissionFee.description")}</Text>
        <Text pt={2}>{t("admissionFee.paragraph2")}</Text>
      </Box>

      <Heading pt={4} as={"h5"} alignSelf={"flex-start"}>
        {t("chargesAndPayments.title")}
      </Heading>
      {chargesAndPayments.sections &&
        chargesAndPayments.sections.map((section, index) => (
          <Box pt={2} key={index}>
            <Text>{section.content}</Text>
          </Box>
        ))}

      <Heading pt={4} ml={4} alignSelf={"flex-start"}>
        {t("recurringSubscriptions.title")}
      </Heading>
      <Text pt={2} pl={8}>
        {t("recurringSubscriptions.description")}
      </Text>
      <Box pt={2} pl={16}>
        <UnorderedList>
          {recurringSubscriptions.failedPaymentActions.options &&
            recurringSubscriptions.failedPaymentActions.options.map(
              (option, index) => <ListItem key={index}>{option}</ListItem>
            )}
        </UnorderedList>
      </Box>

      <Heading pt={4} ml={4} alignSelf={"flex-start"}>
        {t("renewalPeriods.title")}
      </Heading>
      <Text pt={2} pl={8}>
        {t("renewalPeriods.description")}
      </Text>

      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("refundCancellationReactivationTitle")}
      </Heading>

      <Heading pt={2} ml={4} alignSelf={"flex-start"}>
        {t("warranties.title")}
      </Heading>
      <Text pt={2} pl={8}>
        {t("warranties.introduction")}
      </Text>
      <UnorderedList pt={2} pl={16}>
        {warranties.guarantees &&
          warranties.guarantees.map((guarantee, index) => (
            <ListItem key={index}>
              <Text>{guarantee.description}</Text>
            </ListItem>
          ))}
      </UnorderedList>
      <Text pt={2} pl={8}>
        <>
          {partsWarranties[0]}
          <Link
            href={warranties.contactLink.url}
            color="primary.400"
            isExternal
          >
            {warranties.contactLink.text}
          </Link>
          {partsWarranties[1]}
        </>
      </Text>

      <Heading pt={2} ml={4} alignSelf={"flex-start"}>
        {t("serviceCancellation.title")}
      </Heading>
      <Text pt={2} pl={8} width={"100%"} whiteSpace="pre-wrap">
        {t("serviceCancellation.description")}
      </Text>

      <Heading pt={2} ml={4} alignSelf={"flex-start"}>
        {t("permanenceClause.title")}
      </Heading>
      <Text pt={2} pl={8} width={"100%"} whiteSpace="pre-wrap">
        {t("permanenceClause.description")}
      </Text>
      <Text pt={2} pl={8} width={"100%"} whiteSpace="pre-wrap">
        <Flex>
          <Box fontWeight={"bold"}>{t("permanenceClause.subTitle1")}</Box>
          {t("permanenceClause.subTitle2")}
        </Flex>
      </Text>
      <Text pt={2} pl={8} width={"100%"} whiteSpace="pre-wrap">
        {t("permanenceClause.paragraph2")}
      </Text>
      <UnorderedList pt={2} pl={16}>
        {permanenceConditions.conditions &&
          permanenceConditions.conditions.map((condition, index) => (
            <ListItem key={index}>
              <Text>{condition.description}</Text>
            </ListItem>
          ))}
      </UnorderedList>
      <Text pt={2} pl={8} width={"100%"} whiteSpace="pre-wrap">
        <Flex>
          <Box fontWeight={"bold"}>{t("permanenceClause.subTitle3")}</Box>
          {t("permanenceClause.subTitle4")}
        </Flex>
      </Text>
      <Text pt={2} pl={8} width={"100%"} whiteSpace="pre-wrap">
        {t("permanenceClause.paragraph2")}
      </Text>
      <UnorderedList pt={2} pl={16}>
        {permanenceConditions.conditions2 &&
          permanenceConditions.conditions2.map((condition, index) => (
            <ListItem key={index}>
              <Text>{condition.description}</Text>
            </ListItem>
          ))}
      </UnorderedList>

      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("courseContentEquivalenciesThirdPartiesTitle")}
      </Heading>

      <Heading pt={2} ml={4} alignSelf={"flex-start"}>
        {t("thirdPartyRelations.title")}
      </Heading>
      <Text pt={2} pl={8} width={"100%"} whiteSpace="pre-wrap">
        {t("thirdPartyRelations.description")}
      </Text>

      <Heading pt={2} ml={4} alignSelf={"flex-start"}>
        {t("equivalencies.title")}
      </Heading>
      {equivalencies.contents &&
        equivalencies.contents.map((content, index) => (
          <Box pt={2} pl={8} key={index}>
            <Text>{content.description}</Text>
          </Box>
        ))}

      <Heading pt={2} ml={4} alignSelf={"flex-start"}>
        {t("contentScopeSuccessRelation.title")}
      </Heading>
      {contentScope.contents &&
        contentScope.contents.map((content, index) => (
          <Box pt={2} pl={8} key={index}>
            <Text>{content.description}</Text>
          </Box>
        ))}
      <Text pt={2} pl={8} width={"100%"} whiteSpace="pre-wrap">
        {t("contentScopeSuccessRelation.description")}
      </Text>

      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("intellectualPropertyTitle2")}
      </Heading>

      <Heading pt={2} ml={4} alignSelf={"flex-start"}>
        {t("contentOwnership.title")}
      </Heading>
      <Text pt={2} pl={8} width={"100%"} whiteSpace="pre-wrap">
        {t("contentOwnership.description")}
      </Text>

      <Heading pt={2} ml={4} alignSelf={"flex-start"}>
        {t("trademarksDistinctiveSigns.title")}
      </Heading>
      <Text pt={2} pl={8} width={"100%"} whiteSpace="pre-wrap">
        {t("trademarksDistinctiveSigns.description")}
      </Text>

      <Heading pt={2} ml={4} alignSelf={"flex-start"}>
        {t("noRightsAssignment.title")}
      </Heading>
      <Text pt={2} pl={8} width={"100%"} whiteSpace="pre-wrap">
        {t("noRightsAssignment.description")}
      </Text>

      <Heading pt={2} ml={4} alignSelf={"flex-start"}>
        {t("copyrightComplaints.title")}
      </Heading>
      <Text pt={2} pl={8} width={"100%"} whiteSpace="pre-wrap">
        {partsIntroduction[0]}
        <Link
          href={copyrightComplaints.emailLink.url}
          color="primary.400"
          isExternal
        >
          {copyrightComplaints.emailLink.text}
        </Link>
        {partsIntroduction[1]}
      </Text>
      <UnorderedList pl={16}>
        {copyrightComplaints.requirements &&
          copyrightComplaints.requirements.map((requirement, index) => (
            <ListItem key={index}>
              <Text>{requirement}</Text>
            </ListItem>
          ))}
      </UnorderedList>

      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("indemnityLiabilityRelief.title")}
      </Heading>
      <Text pt={2} pl={8} width={"100%"} whiteSpace="pre-wrap">
        {t("indemnityLiabilityRelief.description")}
      </Text>

      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("warrantyExclusion.title")}
      </Heading>
      <Text pt={2} pl={8} width={"100%"} whiteSpace="pre-wrap">
        {t("warrantyExclusion.description")}
      </Text>

      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("liabilityLimitation.title")}
      </Heading>
      <Text pt={2} pl={8} width={"100%"} whiteSpace="pre-wrap">
        {t("liabilityLimitation.description")}
      </Text>

      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("arbitrationClauseGroupWaiver.title")}
      </Heading>
      <Text pt={2} pl={8} width={"100%"} whiteSpace="pre-wrap">
        {t("arbitrationClauseGroupWaiver.description")}
      </Text>
      <Heading pt={2} ml={4} alignSelf={"flex-start"}>
        {t("arbitrationClause.title")}
      </Heading>
      <Text pt={2} pl={8} width={"100%"} whiteSpace="pre-wrap">
        {t("arbitrationClause.description")}
      </Text>
      <Heading pt={2} ml={4} alignSelf={"flex-start"}>
        {t("groupActionWaiver.title")}
      </Heading>
      <Text pt={2} pl={8} width={"100%"} whiteSpace="pre-wrap">
        {t("groupActionWaiver.description")}
      </Text>

      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("terminationBySmartBeemo.title")}
      </Heading>
      <Text pt={2} pl={8} width={"100%"} whiteSpace="pre-wrap">
        {t("terminationBySmartBeemo.description")}
      </Text>

      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("applicableLaw.title")}
      </Heading>
      <Text pt={2} pl={8} width={"100%"} whiteSpace="pre-wrap">
        {t("applicableLaw.description")}
      </Text>

      {/* ......................terminos CONSULTORÍAS SMARTBEEMO...........  */}
      <Heading as={"h4"} pt={4}>
        {t("Terms and Conditions")}
      </Heading>
      <Text fontSize={"20px"}>{t("SMARTBEEMO CONSULTING")}</Text>
      <Text fontSize={"small"} pt={1}>
        {t("termsTitleConsulting")}
      </Text>
      <Text pt={2}>
        {t("termsContentConsulting")}
        <Link
          color={"primary.400"}
          href="https://smartbeemo.com/terminos-y-condiciones"
          isExternal
          rel="noopener noreferrer"
        >
          https://smartbeemo.com/terminos-y-condiciones
        </Link>{" "}
        {t("termsTitleConsulting2")}
      </Text>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("introTitleConsulting")}
      </Heading>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>1.1</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionOne")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>1.2</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionOne2")}
          </Text>
        </Flex>
      </Box>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("secondTitleConsulting")}
      </Heading>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>2.1</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionTwo")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>2.2</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionTwo2")}
          </Text>
        </Flex>
      </Box>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("thirdTitleConsulting")}
      </Heading>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>3.1</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionThree")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>3.2</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionThree2")}
          </Text>
        </Flex>
      </Box>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("fourthTitleConsulting")}
      </Heading>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>4.1</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionFour")}{" "}
            <Text
              as="span"
              fontWeight="bold"
              textDecoration="underline"
              fontStyle="italic"
            >
              total
            </Text>{" "}
            {t("SectionFour2")}{" "}
          </Text>
        </Flex>
      </Box>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("fiveTitleConsulting")}
      </Heading>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>5.1</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionFive")}
          </Text>
        </Flex>
      </Box>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("sixTitleConsulting")}
      </Heading>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>6.1</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionSix")}{" "}
            <Link
              color={"primary.400"}
              href="https://smartbeemo.com/terminos-y-condiciones/"
              isExternal
              rel="noopener noreferrer"
            >
              Términos y Condiciones Generales,
            </Link>{" "}
            {t("SectionSix2")}{" "}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>6.2</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionSix3")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>6.3</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionSix4")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>6.4</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionSix5")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>6.5</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionSix6")}
          </Text>
        </Flex>
      </Box>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("sevenTitleConsulting")}
      </Heading>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>7.1</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionSeven")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>7.2</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionSeven2")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>7.3</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionSeven3")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>7.4</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionSeven4")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>7.5</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionSeven5")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>7.6</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionSeven6")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>7.7</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionSeven7")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>7.8</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionSeven8")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>7.9</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionSeven9")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>7.10</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionSeven10")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>7.11</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionSeven11")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>7.12</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionSeven12")}
          </Text>
        </Flex>
      </Box>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("eightTitleConsulting")}
      </Heading>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>8.1</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionEight")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>8.2</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionEight2")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>8.3</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionEight3")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>8.4</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionEight4")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>8.5</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionEight5")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>8.6</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionEight6")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>8.7</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionEight7")}
          </Text>
        </Flex>
      </Box>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("nineTitleConsulting")}
      </Heading>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>9.1</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionNine")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>9.2</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionNine2")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>9.3</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionNine3")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>9.4</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionNine4")}
          </Text>
        </Flex>
      </Box>
      {/* tabla....................... */}
      <Box p={4}>
        <Heading
          as="h2"
          size="md"
          textAlign="center"
          border="2px solid black"
          borderBottom="none"
          p={2}
        >
          PENALIDADES POR CANCELACIÓN EXTEMPORÁNEA DE LA SESIÓN DE CONSULTORÍA
        </Heading>
        <Box overflowX="auto">
          <Table variant="simple" border="2px solid black">
            <Thead>
              <Tr>
                <Th border="2px solid black">
                  # de horas previas a la fecha convenida para la Sesión
                </Th>
                <Th border="2px solid black">Penalidad</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td border="2px solid black">
                  Cuatro (4) días calendario antes de la fecha convenida para la
                  celebración de la sesión de consultoría
                </Td>
                <Td border="2px solid black">
                  Deberá cancelar un 25% del total del importe de la hora
                  acordada.
                </Td>
              </Tr>
              <Tr>
                <Td border="2px solid black">
                  Entre 36 horas y 72 horas antes de la fecha convenida para la
                  celebración de la sesión de consultoría
                </Td>
                <Td border="2px solid black">
                  Deberá cancelar un 30% del total del importe de la hora
                  acordada.
                </Td>
              </Tr>
              <Tr>
                <Td border="2px solid black">
                  Entre 24 y 36 horas antes de la fecha convenida para la
                  celebración de la sesión de consultoría
                </Td>
                <Td border="2px solid black">
                  Deberá cancelar un total de 40% del total del importe de la
                  hora acordada.
                </Td>
              </Tr>
              <Tr>
                <Td border="2px solid black">
                  Entre 0 y 24 horas antes de la fecha convenida para la
                  celebración de la sesión de consultoría
                </Td>
                <Td border="2px solid black">
                  Deberá cancelar un total de 50% del total del importe de la
                  hora acordada.
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      </Box>
      {/* tabla....................... */}
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>9.5</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionNine5")}
          </Text>
        </Flex>
      </Box>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("tenTitleConsulting")}
      </Heading>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>10.1</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionTen")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>10.2</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionTen2")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>10.3</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionTen3")}
          </Text>
        </Flex>
      </Box>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("elevenTitleConsulting")}
      </Heading>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>11.1</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionEleven")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>11.2</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionEleven2")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>11.3</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionEleven3")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>11.4</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionEleven4")}
          </Text>
        </Flex>
      </Box>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("twelveTitleConsulting")}
      </Heading>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>12.1</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionTwelve")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>12.2</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionTwelve2")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>12.3</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionTwelve3")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>12.4</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionTwelve4")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>12.5</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionTwelve5")}{" "}
            <Link
              color={"primary.400"}
              href="https://smartbeemo.com/politica-de-privacidad/?utm_source=google&utm_medium=cpl&utm_campaign=amazon&gclid=CjwKCAiA_5WvBhBAEiwAZtCU7xurSFwQiFJxci4KbJzEcAuy3Q106qdOdFkBkL0BT66wTXsx4gLSMBoC0OIQAvD_BwE"
              isExternal
              rel="noopener noreferrer"
            >
              política de tratamiento de datos de la compañía.
            </Link>{" "}
          </Text>
        </Flex>
      </Box>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("thirteenTitleConsulting")}
      </Heading>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>13.1</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionThirteen")}
          </Text>
        </Flex>
      </Box>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("fourteenTitleConsulting")}
      </Heading>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>14.1</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionFourteen")}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>14.2</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionFourteen2")}
          </Text>
        </Flex>
      </Box>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("fifteenTitleConsulting")}
      </Heading>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>15.1</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionFifteen")}
          </Text>
        </Flex>
      </Box>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("sixteenTitleConsulting")}
      </Heading>
      <Box>
        <Flex alignItems="flex-start">
          <Text mt={"9px"}>16.1</Text>
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("SectionSixteen")}
          </Text>
        </Flex>
      </Box>
      <Heading as="h4" pt={4}>
        {marketplaceTerms.title}
      </Heading>
      <Text fontSize="20px">{marketplaceTerms.subtitle}</Text>
      <Text fontSize="small" pt={1}>
        {marketplaceTerms.version}
      </Text>
      <Text pt={2}>
        {partsMarketplaceTerms[0]}
        <Link
          color="primary.400"
          href={marketplaceTerms.contentLink.url}
          isExternal
          rel="noopener noreferrer"
        >
          {marketplaceTerms.contentLink.text}
        </Link>
        {partsMarketplaceTerms[1]}
      </Text>

      {marketplaceTerms.sections &&
        marketplaceTerms.sections.map((section, sectionIndex) => (
          <Box key={sectionIndex} mt={4}>
            <Heading as="h5" alignSelf="flex-start">
              {sectionIndex + 1}. {section.title}
            </Heading>
            {section.paragraphs &&
              section.paragraphs.map((paragraph, paragraphIndex) => (
                <Text key={paragraphIndex} pt={2} pl={4}>
                  {sectionIndex + 1}.{paragraphIndex + 1}. {paragraph}
                </Text>
              ))}
          </Box>
        ))}

      <Flex align={"center"} flexDir={"column"}>
        <Text pt={2}>{t("finalParagraph")}</Text>
      </Flex>

      <Heading as="h4" pt={4}>
        {t("ChinaTermsAndConditions.title")}
      </Heading>
      <Text fontSize="20px">{t("ChinaTermsAndConditions.subtitle")}</Text>
      <Text fontSize="small" pt={1}>
        {t("ChinaTermsAndConditions.version")}
      </Text>
      <Text pt={2}>
        {t("ChinaTermsAndConditions.introduction.part1")}
        <Link
          color="primary.400"
          href={t("ChinaTermsAndConditions.introduction.link")}
          isExternal
          rel="noopener noreferrer"
        >
          {t("ChinaTermsAndConditions.introduction.link")}
        </Link>
        {t("ChinaTermsAndConditions.introduction.part2")}
      </Text>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("ChinaTermsAndConditions.term1.title")}
      </Heading>
      <Box>
        <Flex alignItems="flex-start">
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term1.part1")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term1.part2")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term1.part3")}
          </Text>
        </Flex>
      </Box>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("ChinaTermsAndConditions.term2.title")}
      </Heading>
      <Box>
        <Flex alignItems="flex-start">
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term2.part1")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term2.part2")}
          </Text>
        </Flex>
      </Box>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("ChinaTermsAndConditions.term3.title")}
      </Heading>
      <Box>
        <Flex alignItems="flex-start">
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term3.part1")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term3.part2")}
          </Text>
        </Flex>
      </Box>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("ChinaTermsAndConditions.term4.title")}
      </Heading>
      <Box>
        <Flex alignItems="flex-start">
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term4.part1")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term4.part2")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term4.part2-1")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term4.part2-2")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term4.part2-3")}
          </Text>
        </Flex>
      </Box>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("ChinaTermsAndConditions.term5.title")}
      </Heading>
      <Box>
        <Flex alignItems="flex-start">
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term5.part1")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start" flexDir={"column"}>
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term5.part2")}
          </Text>
          <br></br>
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term5.part2-1")}
          </Text>
        </Flex>
      </Box>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("ChinaTermsAndConditions.term6.title")}
      </Heading>
      <Box flexDir={"column"}>
        <Flex alignItems="flex-start">
          <Text fontWeight={"bold"} pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part1")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part1-1")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part1-2")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part1-3")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part1-4")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part1-5")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part1-6")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part1-7")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part1-8")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part1-9")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part1-10")}
          </Text>
        </Flex>
      </Box>
      <Box flexDir={"column"}>
        <Flex alignItems="flex-start">
          <Text fontWeight={"bold"} pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part2")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part2-1")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part2-2")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part2-3")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part2-4")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part2-5")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part2-6")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part2-7")}
          </Text>
        </Flex>
        <Flex pl={10} my={8} alignItems="flex-start">
          <Table variant="simple" border="2px solid black">
            <Thead>
              <Tr>
                <Th border="2px solid black">
                  {t("ChinaTermsAndConditions.term6.part2-7-table.th1")}
                </Th>
                <Th border="2px solid black">
                  {t("ChinaTermsAndConditions.term6.part2-7-table.th2")}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td border="2px solid black">
                  {t("ChinaTermsAndConditions.term6.part2-7-table.td1-1")}
                </Td>
                <Td border="2px solid black">
                  {t("ChinaTermsAndConditions.term6.part2-7-table.td2-1")}
                </Td>
              </Tr>
              <Tr>
                <Td border="2px solid black">
                  {t("ChinaTermsAndConditions.term6.part2-7-table.td1-2")}
                </Td>
                <Td border="2px solid black">
                  {t("ChinaTermsAndConditions.term6.part2-7-table.td2-2")}
                </Td>
              </Tr>
              <Tr>
                <Td border="2px solid black">
                  {t("ChinaTermsAndConditions.term6.part2-7-table.td1-3")}
                </Td>
                <Td border="2px solid black">
                  {t("ChinaTermsAndConditions.term6.part2-7-table.td2-3")}
                </Td>
              </Tr>
              <Tr>
                <Td border="2px solid black">
                  {t("ChinaTermsAndConditions.term6.part2-7-table.td1-4")}
                </Td>
                <Td border="2px solid black">
                  {t("ChinaTermsAndConditions.term6.part2-7-table.td2-4")}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part2-8")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part2-9")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part2-10")}
          </Text>
        </Flex>
      </Box>
      <Box flexDir={"column"}>
        <Flex alignItems="flex-start">
          <Text fontWeight={"bold"} pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part3")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part3-1")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part3-2")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part3-3")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part3-4")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part3-4")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part3-5")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part3-6")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part3-7")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part3-8")}
          </Text>
        </Flex>
      </Box>
      <Box flexDir={"column"}>
        <Flex alignItems="flex-start">
          <Text fontWeight={"bold"} pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part4")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part4-1")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part4-2")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part4-3")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part4-4")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part4-4")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part4-5")}
          </Text>
        </Flex>
      </Box>
      <Box flexDir={"column"}>
        <Flex alignItems="flex-start">
          <Text fontWeight={"bold"} pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part5")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part5-subtitle")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part5-1")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part5-2")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part5-3")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term6.part5-4")}
          </Text>
        </Flex>
      </Box>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("ChinaTermsAndConditions.term7.title")}
      </Heading>
      <Box flexDir={"column"}>
        <Flex alignItems="flex-start">
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term7.part1")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term7.part2")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term7.part3")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term7.part3-1")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term7.part3-2")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term7.part3-3")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term7.part3-4")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term7.part4")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term7.part4-1")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term7.part4-2")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term7.part4-3")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term7.part5")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term7.part5-1")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term7.part5-2")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term7.part5-3")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term7.part5-4")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term7.part5-5")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term7.part5-6")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={8} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term7.part5-7")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term7.part6")}
          </Text>
        </Flex>
      </Box>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("ChinaTermsAndConditions.term8.title")}
      </Heading>
      <Box>
        <Flex alignItems="flex-start">
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term8.part1")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start" flexDir={"column"}>
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term8.part2")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start" flexDir={"column"}>
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term8.part3")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start" flexDir={"column"}>
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term8.part4")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start" flexDir={"column"}>
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term8.part5")}
          </Text>
        </Flex>
      </Box>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("ChinaTermsAndConditions.term9.title")}
      </Heading>
      <Box>
        <Flex alignItems="flex-start">
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term9.part1")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start" flexDir={"column"}>
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term9.part2")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start" flexDir={"column"}>
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term9.part3")}
          </Text>
        </Flex>
      </Box>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("ChinaTermsAndConditions.term10.title")}
      </Heading>
      <Box>
        <Flex alignItems="flex-start">
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term10.part1")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start" flexDir={"column"}>
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term10.part2")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start" flexDir={"column"}>
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term10.part3")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start" flexDir={"column"}>
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term10.part4")}
          </Text>
        </Flex>
      </Box>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("ChinaTermsAndConditions.term12.title")}
      </Heading>
      <Box>
        <Flex alignItems="flex-start">
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term12.part1")}
          </Text>
        </Flex>
      </Box>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("ChinaTermsAndConditions.term13.title")}
      </Heading>
      <Box>
        <Flex alignItems="flex-start">
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term13.part1")}
          </Text>
        </Flex>
        <Flex alignItems="flex-start" flexDir={"column"}>
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term13.part2")}
          </Text>
        </Flex>
      </Box>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("ChinaTermsAndConditions.term14.title")}
      </Heading>
      <Box>
        <Flex alignItems="flex-start">
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term14.part1")}
          </Text>
        </Flex>
      </Box>
      <Heading as={"h5"} mt={4} alignSelf={"flex-start"}>
        {t("ChinaTermsAndConditions.term15.title")}
      </Heading>
      <Box>
        <Flex alignItems="flex-start">
          <Text pl={4} pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.term15.part1")}
          </Text>
        </Flex>
      </Box>
      <Box mt={4}>
        <Flex alignItems="flex-start">
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.lastparagraph")}
          </Text>
        </Flex>
      </Box>
      <Box mt={6} w={"100%"}>
        <Flex alignItems="flex-start">
          <Text pt={2} ml={2} alignSelf="flex-start">
            {t("ChinaTermsAndConditions.contactInfo.line1")} <br></br>
            {t("ChinaTermsAndConditions.contactInfo.line2")} <br></br>
            {t("ChinaTermsAndConditions.contactInfo.line3")} <br></br>
            {t("ChinaTermsAndConditions.contactInfo.line4")} <br></br>
          </Text>
        </Flex>
      </Box>
    </PageContainer>
  );
};

export default TermsAndConditionsPage;
