import { Box, Text, BoxProps } from "@chakra-ui/react";

interface IconErrorProps extends BoxProps {
  error: string;
}

export const IconError: React.FC<IconErrorProps> = ({ error, ...rest }) => {
  return (
    <Box {...rest}>
      <Box>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M0.666626 14.0002H15.3333L7.99996 1.3335L0.666626 14.0002ZM8.66663 12.0002H7.33329V10.6668H8.66663V12.0002ZM8.66663 9.3335H7.33329V6.66683H8.66663V9.3335Z"
            fill="#FF5858"
          />
        </svg>
      </Box>
      <Text color="secondary.100" variant="feedback">
        {error}
      </Text>
    </Box>
  );
};
