import { Route, Routes, useLocation } from "react-router-dom";
import { useScrollToTop } from "./hooks/useScrolltoTop";
import { useEffect } from "react";
import { AppRoute, PublicRoute } from "./AppRoute";
import Home from "./pages/Home";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import CreatePasswordPage from "./pages/CreatePassword";
import PageStreaming from "./pages/academy/LiveSessionZoom/Streaming/PageStreaming";
import SignUpPage from "./pages/SignUpPage";
import TermsAndConditionsPage from "./pages/TermsConditionPage";

export const RouterLogin: React.FC = () => {
  const location = useLocation();
  useScrollToTop();

  useEffect(() => {
    if (window.ga !== undefined) {
      ga("send", "pageview", location.pathname);
    }
  }, [location]);

  return (
    <Routes>
      <Route path={AppRoute.Base} element={<Home />} />

      <Route path={PublicRoute.SignUp} element={<SignUpPage />} />
      <Route path={AppRoute.Login} element={<Home />} />

      <Route
        path={`${PublicRoute.CreatePassword}/:token`}
        element={<CreatePasswordPage />}
      />

      <Route
        path={`${PublicRoute.ResetPassword}/:token`}
        element={<ResetPasswordPage />}
      />

      <Route
        path={PublicRoute.ForgotPasssword}
        element={<ForgotPasswordPage />}
      />

      <Route
        path={AppRoute.TermsAndConditions}
        element={<TermsAndConditionsPage />}
      />

      <Route
        path={`${PublicRoute.LiveSessionPortalV1}/:liveSessionId/:channelName/:userId/:name`}
        element={<PageStreaming />}
      />

      <Route path="*" element={<Home />} />
    </Routes>
  );
};
