import { MentorProvider } from "../providers/MentorProvider";
import ErrorBoundary from "./ErrorBoundary";
import PageStreamingContent from "./PageStreamingContent";

const PageStreaming = () => {
  return (
    <ErrorBoundary>
      <MentorProvider>
        <PageStreamingContent />
      </MentorProvider>
    </ErrorBoundary>
  );
};

export default PageStreaming;
