import { useRef, useEffect } from "react";
import ReactPlayer, { ReactPlayerProps } from "react-player";
import { Center, CenterProps } from "@chakra-ui/react";

interface VideoProps extends CenterProps {
  video: Omit<ReactPlayerProps, "height" | "width">;
}

const Video = ({ video, ...restProps }: VideoProps) => {
  const playerRef = useRef<ReactPlayer | null>(null);

  useEffect(() => {
    const handleFullscreen = () => {
      const iframe = (
        playerRef.current as ReactPlayer | null
      )?.getInternalPlayer();

      if (iframe instanceof HTMLIFrameElement && iframe.requestFullscreen) {
        iframe.requestFullscreen();
      }
    };

    const iframe = (
      playerRef.current as ReactPlayer | null
    )?.getInternalPlayer();

    if (iframe instanceof HTMLIFrameElement) {
      iframe.addEventListener("play", handleFullscreen);
    }

    return () => {
      if (iframe instanceof HTMLIFrameElement) {
        iframe.removeEventListener("play", handleFullscreen);
      }
    };
  }, [video.url]);

  return (
    <Center
      aspectRatio={16 / 9}
      bgColor={"black"}
      objectFit={"contain"}
      overflow={"hidden"}
      {...restProps}
    >
      <ReactPlayer
        ref={playerRef}
        key={video.url} // Esto forzará la recarga del iframe
        controls
        playing
        width="100%"
        height="100%"
        {...video}
      />
    </Center>
  );
};

export { Video };
