import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { Ico } from "@/assets/icons";
import { colors } from "@/components/Theme/colors";
import { ProgramAccordion } from "./ProgramAccordion";
import { GetAllDataProgramByIdQuery } from "@/pages/academy/Programs/graphql/getAllProgramDataById.generated";
import { GetCohortByIdQuery } from "@/pages/Cohorts/graphql/getCohortById.generated";

interface ProgramResumeProps {
  programData?: GetAllDataProgramByIdQuery;
  programType: string;
  cohortData?: GetCohortByIdQuery;
}
export const ProgramContent: React.FC<ProgramResumeProps> = ({
  programData,
  programType,
  cohortData,
}) => {
  return (
    <Flex
      px={{ base: 4, xl: 0 }}
      flexDirection={"column"}
      maxW={"944px"}
      w={"full"}
    >
      <Text fontWeight={"bold"}>Contenido del Programa:</Text>
      <Flex pt={2} gap={6}>
        <Flex gap={2} alignItems={"center"}>
          <Ico.LaptopPlay fontSize={"16px"} color={colors.primary[200]} />
          <Text fontSize={"12px"} fontWeight={"bold"}>
            {cohortData?.cohort?.cant_courses_program}
          </Text>
        </Flex>
        <Flex gap={2} alignItems={"center"}>
          <Ico.BookOpen fontSize={"16px"} color={colors.primary[200]} />
          <Text fontSize={"12px"} fontWeight={"bold"}>
            {cohortData?.cohort?.cant_lessons_program}
          </Text>
        </Flex>
        <Flex gap={2} alignItems={"center"}>
          <Ico.Clock2 fontSize={"16px"} color={colors.primary[200]} />
          <Text fontSize={"12px"} fontWeight={"bold"}>
            {cohortData?.cohort?.cant_hours_program}
          </Text>
        </Flex>
      </Flex>
      <Flex flexDir={"column"} pt={4} gap={4}>
        {programData?.getAllProgramDataById.map((programContent: any) => {
          return programContent.sessions.map((session: any) => {
            return (
              <ProgramAccordion
                key={session.id}
                sessionData={session}
                programType={programType}
              />
            );
          });
        })}
      </Flex>
    </Flex>
  );
};
