/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  Box,
  Flex,
  Heading,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import useGA4Event, {
  GA4EventAction,
  GA4EventCategory,
  GA4EventLabel,
} from "@/hooks/useGA4Event";
import { useCallback, useContext, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { LiveSessionCard as LiveSessionCardType } from "./types";
import { BigSpinner } from "@/components/BigSpinner";
import { useUser } from "@/providers/useUser";
import { useLazyQuery, useMutation } from "@apollo/client";
import { IsSessionScheduledAtSameTimeDocument } from "@/components/LiveSessions/graphql/isSessionsScheduledAtSameTIme.generated";
import { LiveContentType } from "@/components/LiveSessions/types/LiveBar.type";
import { LiveSession, LiveSessionStatus } from "@/schemaTypes";
import { CreateLiveSessionSchedulingDocument } from "@/components/LiveSessions/graphql/CreateSchullingSessions.generated";
import { AppRoute } from "@/AppRoute";
import LiveSessionCard from "./LiveSessionCard";
import ModalSchedulePosition from "@/components/LiveSessions/ModalSchedulePosition";
import ModalCancelSchedullingLiveSession from "@/components/LiveSessions/ModalCancelSchedullingLiveSession";
import AcademyCarousel from "@/components/AcademyCarousel";
import PreviewCard from "./PreviewCard";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import { t } from "i18next";
import { learnContext } from "@/pages/academy/Learn/providers/learnProvider";
import EmpityStateComponent from "@/components/EmpityStates/EmpityStateComponent";
import { Ico } from "@/assets/icons";
import { useNavigate } from "react-router-dom";

// THIS INTERFACE IS DEPRECATED. PLEASE, DO NOT USE IT.
// refer to learnContext instead
interface LiveSessionsProps {
  selectSubCategory?: string | null;
  liveSessions?: (LiveSessionCardType | undefined)[];
  schedulledLiveSessions?: (LiveSessionCardType | undefined)[];
  liveSessionsLoading?: boolean;
  refetchLiveSessions?: () => void;
  isColumn: boolean;
}

const LiveSessions: React.FC<LiveSessionsProps> = ({ isColumn }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { timezone, user } = useUser();
  const { sendEvent } = useGA4Event();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const context = useContext(learnContext);

  if (!context) {
    throw new Error("useAcademyEntities must be used within a LearnProvider");
  }

  const {
    liveSessions: sessions,
    allSessionsLoading,
    refetchAllSessions,
  } = context;
  const { next: liveSessions, scheduled: scheduledSessions } = sessions || {};

  const [filteredSessions, setFilteredSessions] = useState({
    next: liveSessions,
    scheduled: scheduledSessions,
  });

  const [selecetedGoingOnSession, setSelectedGoingOnSession] = useState<
    LiveSessionCardType | undefined
  >();

  const [previousPreviewSession, setPreviousPreviewSession] = useState<
    string | undefined
  >();

  const [sessionToSchedule, setSessionToSchedule] = useState<
    LiveSessionCardType | undefined
  >();

  const [sessionToSchedulesSameTime, setSessionToScheduleSameTime] =
    useState<LiveContentType>();

  const [verifySessionScheduledAtSameTime] = useLazyQuery(
    IsSessionScheduledAtSameTimeDocument,
    { fetchPolicy: "no-cache" }
  );
  const handleVerifySchedulingSession = async (
    session: LiveSessionCardType
  ) => {
    const haSsessionScheduled = await verifySessionScheduledAtSameTime({
      variables: { liveSessionsId: session?.id },
    });

    if (haSsessionScheduled.data?.isSessionScheduledAtSameTime) {
      setCanSchedule(false);
      setSessionToSchedule(session);
      setSessionToScheduleSameTime(
        haSsessionScheduled?.data
          ?.isSessionScheduledAtSameTime as LiveContentType
      );
    } else {
      setCanSchedule(true);
      setSessionToSchedule(session);
    }
  };

  const [createSchedullingSession] = useMutation(
    CreateLiveSessionSchedulingDocument
  );
  const handleSchedullingSession = async (session?: LiveSessionCardType) => {
    if (session && timezone) {
      createSchedullingSession({
        variables: {
          createLiveSessionsSchedulingInput: {
            liveSessionsId: session?.id,
            timezone: timezone,
          },
        },
      })
        .then(() => {
          refetchAllSessions();
          handleScheduleModalClose();
          sendEvent({
            action: `${GA4EventAction.RegisterLiveSessionAttendance}-${session.name}`,
            label: GA4EventLabel.RegisterLiveSessionAttendance,
            category: GA4EventCategory.LiveSessions,
          });
        })
        .catch(() => {
          refetchAllSessions();
          handleScheduleModalClose();
          sendEvent({
            action: `${GA4EventAction.RegisterLiveSessionAttendance}-${session.name}`,
            label: GA4EventLabel.RegisterLiveSessionAttendance,
            category: GA4EventCategory.LiveSessions,
          });
        });
    }
  };

  const [canSchedule, setCanSchedule] = useState(false);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [isCancelScheduleModalOpen, setIsCancelScheduleModalOpen] =
    useState(false);

  const handleScheduleClick = (session: LiveSessionCardType) => {
    handleVerifySchedulingSession(session).then(() =>
      setIsScheduleModalOpen(true)
    );
  };

  const handleScheduleModalClose = () => {
    setIsScheduleModalOpen(false);
    setSessionToSchedule(undefined);
  };

  const handleCancelScheduleClick = (session: LiveSessionCardType) => {
    setSessionToSchedule(session);
    setIsCancelScheduleModalOpen(true);
  };

  const handleCancelScheduleModalClose = () => {
    setIsCancelScheduleModalOpen(false);
    setSessionToSchedule(undefined);
  };

  const calculateFilterOptions = (sessions?: LiveSessionCardType[]) => {
    if (!sessions) return [];

    return [
      "Todos",
      ...Array.from(
        new Set(
          sessions.map(
            (session) => session?.type?.pluralName || session?.type?.name
          ) as string[]
        )
      ),
    ];
  };

  const [liveSessionsFilters, setLiveSessionsFilters] = useState<string[]>([]);
  const [scheduledSessionsFilters, setScheduledSessionsFilters] = useState<
    string[]
  >([]);

  useEffect(() => {
    const sessionsFilters = calculateFilterOptions(
      (sessions?.next || []) as unknown as LiveSessionCardType[]
    );
    setLiveSessionsFilters(sessionsFilters);

    const scheduledSessionsFilters = calculateFilterOptions(
      (sessions?.scheduled || []) as unknown as LiveSessionCardType[]
    );
    setScheduledSessionsFilters(scheduledSessionsFilters);
  }, [sessions]);

  const handleFilterClick = (
    selectedFilter: string,
    filterScheduled = false
  ) => {
    const sessionsToFilter = filterScheduled
      ? [...sessions.scheduled]
      : [...sessions.next];

    const filteredSessions = sessionsToFilter.filter(
      (session) =>
        selectedFilter === "Todos" ||
        session?.type?.pluralName === selectedFilter ||
        session?.type?.name === selectedFilter
    );

    return setFilteredSessions({
      next: filterScheduled ? liveSessions : filteredSessions,
      scheduled: filterScheduled ? filteredSessions : scheduledSessions,
    });
  };

  useEffect(() => {
    setFilteredSessions({
      next: liveSessions,
      scheduled: scheduledSessions,
    });
  }, [liveSessions, scheduledSessions]);

  const playRandomSession = async () => {
    if (previousPreviewSession) {
      setPreviousPreviewSession(undefined);
      setSelectedGoingOnSession(undefined);
    }

    const goingOnSessions = scheduledSessions?.filter((session) => {
      const now = DateTime.now().setZone(timezone);
      const sessionStart = DateTime.fromISO(session.startDate.toString(), {
        zone: timezone,
      });
      const sessionEnd = DateTime.fromISO(session.endDate.toString(), {
        zone: timezone,
      });
      const hideTime = sessionEnd.minus({ minutes: 15 });

      return (
        now >= sessionStart &&
        now < hideTime &&
        session.status !== LiveSessionStatus.Ended
      );
    });

    if (!goingOnSessions?.length) {
      setSelectedGoingOnSession(undefined);
      return;
    }

    const randomSession =
      goingOnSessions[Math.floor(Math.random() * goingOnSessions.length)];

    if (randomSession) {
      setPreviousPreviewSession(selecetedGoingOnSession?.id);
      setSelectedGoingOnSession(
        randomSession as unknown as LiveSessionCardType
      );
    } else {
      setSelectedGoingOnSession(undefined);
    }
  };
  useEffect(() => {
    const checkSessionVisibility = () => {
      if (selecetedGoingOnSession?.endDate) {
        const now = DateTime.now().setZone(timezone);
        const sessionEnd = DateTime.fromISO(
          selecetedGoingOnSession.endDate.toString(),
          {
            zone: timezone,
          }
        );
        const hideTime = sessionEnd.minus({ minutes: 15 });

        if (
          now >= hideTime ||
          selecetedGoingOnSession.status === LiveSessionStatus.Ended
        ) {
          setSelectedGoingOnSession(undefined);
          playRandomSession();
        } else {
          const msUntilHide = hideTime.diff(now).as("milliseconds");
          if (msUntilHide > 0) {
            setTimeout(checkSessionVisibility, msUntilHide);
          }
        }
      }
    };

    checkSessionVisibility();
  }, [selecetedGoingOnSession, timezone]);

  const enterToSession = useCallback(
    (session: LiveSessionCardType) => {
      const now = DateTime.now().setZone(timezone);
      const sessionStart = DateTime.fromISO(session.startDate.toString(), {
        zone: timezone,
      });
      const earlyAccessTime = sessionStart.minus({ minutes: 20 });

      const isSessionMentor =
        user?.id &&
        (session.mainMentors?.some((mentor) => mentor?.id === user.id) ||
          session.secondaryMentors?.some((mentor) => mentor?.id === user.id));

      if (previousPreviewSession && previousPreviewSession !== session.id) {
        setPreviousPreviewSession(undefined);
        setSelectedGoingOnSession(undefined);
      }

      if (isSessionMentor) {
        if (now < earlyAccessTime) {
          toast({
            title: t("Aún no es tiempo de entrar a la sesión"),
            status: "info",
            isClosable: true,
            duration: null,
          });
          return;
        }
      } else {
        if (now < sessionStart) {
          toast({
            title: t("Aún no es tiempo de entrar a la sesión"),
            status: "info",
            isClosable: true,
            duration: null,
          });
          return;
        }
      }

      const liveSessionPath = isSessionMentor
        ? `${AppRoute.SettingDevices}/${session?.id}`
        : `${AppRoute.LiveSessionStreaming}/${session?.id}`;

      setPreviousPreviewSession(session.id);
      navigate(liveSessionPath);
    },
    [timezone, user?.id, navigate, previousPreviewSession, toast]
  );

  useEffect(() => {
    return () => {
      setSelectedGoingOnSession(undefined);
      setPreviousPreviewSession(undefined);
    };
  }, []);

  useEffect(() => {
    playRandomSession();
  }, [scheduledSessions, allSessionsLoading]);

  return (
    <Flex
      flex={{ base: "100%", md: "100%", "2xl": "1" }}
      my={isColumn ? 0 : "24px"}
      px={0}
      flexDir={"column"}
      overflow={"hidden"}
      order={{ base: 1, "2xl": 2 }}
    >
      <Heading
        as={"h5"}
        px={isMobile ? "16px" : "auto"}
        mb={"16px"}
        overflow="hidden"
        fontSize={isColumn ? "16px" : "20px"}
      >
        <Trans>Live Sessions</Trans>
      </Heading>

      {allSessionsLoading && <BigSpinner />}

      {!allSessionsLoading && (
        <Tabs isFitted>
          <TabList
            pl={0}
            ml={{ base: "16px", md: isColumn ? "0px" : "16px", lg: 0 }}
            color={"neutral.300"}
            w={
              isColumn
                ? { base: "max-content", md: "max-content", "2xl": "100%" }
                : "max-content"
            }
          >
            {["Próximas", "Agendadas"].map((tab, index) => (
              <Tab
                key={tab}
                _selected={{ color: "secondary.300" }}
                _active={{ color: "secondary.300" }}
                pt={0}
                gap={"0.25rem"}
              >
                <Text variant={"caption"}>{tab}</Text>
                {index === 1 && !allSessionsLoading && (
                  <Flex
                    w={"20px"}
                    h={"20px"}
                    m={"2px"}
                    bg={"secondary.300"}
                    borderRadius={"50%"}
                    justify={"center"}
                    align={"center"}
                  >
                    <Text variant={"feedback"} color={"#fff"}>
                      {scheduledSessions?.length}
                    </Text>
                  </Flex>
                )}
              </Tab>
            ))}
          </TabList>

          <TabIndicator
            mt="-2px"
            height="2px"
            bg="secondary.300"
            borderRadius="8px"
          />

          <TabPanels pb={0}>
            <TabPanel p={0} mt={"16px"}>
              {!liveSessions?.length && !allSessionsLoading && (
                <Text
                  mt={"32px"}
                  ml={isMobile ? "16px" : "0"}
                  variant={"placeholder"}
                >
                  En este momento no tenemos sesiones disponibles.
                </Text>
              )}

              {!!liveSessions?.length && !allSessionsLoading && (
                <AcademyCarousel
                  isColumn={isColumn}
                  filterOptions={liveSessionsFilters}
                  handleFilterChange={handleFilterClick}
                >
                  {selecetedGoingOnSession &&
                    selecetedGoingOnSession.id !== previousPreviewSession && (
                      <Flex
                        minW={"300px"}
                        minH={"261px"}
                        maxH={"261px"}
                        maxW={isMobile ? "100%" : "300px"}
                      >
                        <PreviewCard
                          sessionSelectedStarted={
                            selecetedGoingOnSession as unknown as LiveSession
                          }
                          enterToSession={() =>
                            enterToSession(selecetedGoingOnSession)
                          }
                        />
                      </Flex>
                    )}
                  {filteredSessions?.next.map((session) => (
                    <LiveSessionCard
                      key={uuidv4()}
                      isColumn={isColumn}
                      session={session as unknown as LiveSessionCardType}
                      handleScheduleClick={handleScheduleClick}
                      handleCancelScheduleClick={handleCancelScheduleClick}
                      enterToSession={enterToSession}
                    />
                  ))}
                </AcademyCarousel>
              )}
            </TabPanel>
            <TabPanel p={0} mt={"16px"}>
              {scheduledSessions &&
                !scheduledSessions.length &&
                !allSessionsLoading && (
                  <Box
                    mt={{ base: "36px", lg: "40px" }}
                    mb={{ base: "40px", lg: "96px" }}
                  >
                    <EmpityStateComponent
                      IconComponent={Ico.EmpityState1}
                      dataFontSize={{ base: "82px", lg: "168px" }}
                      textUp={
                        "You don't have any sessions in your schedule yet."
                      }
                      textDown={"Do you feel like programming the next one?"}
                    />
                  </Box>
                )}
              {scheduledSessions &&
                !!scheduledSessions.length &&
                !allSessionsLoading && (
                  <AcademyCarousel
                    isColumn={isColumn}
                    filterOptions={scheduledSessionsFilters}
                    handleFilterChange={(filter) =>
                      handleFilterClick(filter, true)
                    }
                  >
                    {selecetedGoingOnSession &&
                      selecetedGoingOnSession.id !== previousPreviewSession && (
                        <Flex
                          minW={"300px"}
                          minH={"261px"}
                          maxH={"261px"}
                          maxW={isMobile ? "100%" : "300px"}
                        >
                          <PreviewCard
                            sessionSelectedStarted={
                              selecetedGoingOnSession as unknown as LiveSession
                            }
                            enterToSession={() =>
                              enterToSession(selecetedGoingOnSession)
                            }
                          />
                        </Flex>
                      )}
                    {scheduledSessions?.map((session) => (
                      <LiveSessionCard
                        isColumn={isColumn}
                        key={session?.name}
                        session={session as unknown as LiveSessionCardType}
                        handleScheduleClick={handleScheduleClick}
                        handleCancelScheduleClick={handleCancelScheduleClick}
                        enterToSession={enterToSession}
                      />
                    ))}
                    {scheduledSessions?.map((session) => (
                      <LiveSessionCard
                        isColumn={isColumn}
                        key={session?.name}
                        session={session as unknown as LiveSessionCardType}
                        handleScheduleClick={handleScheduleClick}
                        handleCancelScheduleClick={handleCancelScheduleClick}
                        enterToSession={enterToSession}
                      />
                    ))}
                  </AcademyCarousel>
                )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
      <ModalSchedulePosition
        isOpen={isScheduleModalOpen}
        onClose={handleScheduleModalClose}
        timezone={timezone}
        sessionToSchedule={sessionToSchedule}
        sessionToSchedulesSameTime={sessionToSchedulesSameTime}
        handleSchedullingSession={handleSchedullingSession}
        isSuccessSchedulling={canSchedule}
      />

      <ModalCancelSchedullingLiveSession
        isOpen={isCancelScheduleModalOpen}
        onClose={handleCancelScheduleModalClose}
        sessionToSchedule={sessionToSchedule}
        setupdateRequests={setCanSchedule}
        sessionByWeek={sessionToSchedule}
        refetchLiveSessions={refetchAllSessions}
      />
    </Flex>
  );
};

export default LiveSessions;
