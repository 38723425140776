import * as Types from '../../../../schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetAllDataProgramByIdQueryVariables = Types.Exact<{
  getAllDataProgramInput: Types.GetAllDataProgramInput;
}>;


export type GetAllDataProgramByIdQuery = { __typename?: 'Query', getAllProgramDataById?: any | null };


export const GetAllDataProgramByIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getAllDataProgramById"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"getAllDataProgramInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetAllDataProgramInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getAllProgramDataById"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"getAllDataProgramInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"getAllDataProgramInput"}}}]}]}}]} as unknown as DocumentNode<GetAllDataProgramByIdQuery, GetAllDataProgramByIdQueryVariables>;