import { Box, Divider, Flex, IconButton, Text } from "@chakra-ui/react";
import { InformationMenu } from "./informationMenu";
import { Ico } from "@/assets/icons";

interface Props {
  isMenuExpanded: boolean;
}

const socialMediaIcons = [
  { name: "facebook", icon: <Ico.Facebook />, url: "https://facebook.com" },
  { name: "instagram", icon: <Ico.Instagram />, url: "https://instagram.com" },
  { name: "linkedIn", icon: <Ico.LinkedIn />, url: "https://linkedin.com" },
  { name: "youtube", icon: <Ico.YouTube />, url: "https://youtube.com" },
];

export const Footer = ({ isMenuExpanded }: Props) => {
  return (
    <Box w="100%" mt={"24px"}>
      {isMenuExpanded && <Divider w="100%" mb={"24px"} color={"neutral.200"} />}
      <InformationMenu isMenuExpanded={isMenuExpanded} />
      {isMenuExpanded && (
        <Flex direction={"column"} justify={"center"}>
          <Flex my="16px" gap={"8px"}>
            {socialMediaIcons.map(({ name, icon, url }) => (
              <IconButton
                aria-label={name}
                as={"a"}
                fontSize={"24px"}
                href={url}
                icon={icon}
                key={name}
                target={"_blank"}
                variant={"link"}
                color={"secondary.300"}
                p={"4px"}
                onClick={() => {}}
              />
            ))}
          </Flex>
          <Text color={"secondary.300"} variant={"tiny"}>
            © 2023-2025 smartBeemo, LLC. Todos los derechos reservados.
          </Text>
        </Flex>
      )}
    </Box>
  );
};
