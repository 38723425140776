import {
  Avatar,
  Badge,
  Box,
  Center,
  Divider,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import { LiveSessionCard as LiveSessionCardType } from "./types";
import { Trans } from "react-i18next";
import { parseLiveSessionsDate, useLiveSessionStatus } from "./utils";
import { Button } from "@/components/Button";
import { LiveSessionStatus } from "@/schemaTypes";
import { Ico } from "@/assets/icons";
import { useUser } from "@/providers/useUser";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "@/AppRoute";

interface LiveSessionCardProps {
  isColumn?: boolean;
  session: LiveSessionCardType;
  handleScheduleClick: (session: LiveSessionCardType) => void;
  handleCancelScheduleClick: (session: LiveSessionCardType) => void;
  enterToSession: (session: LiveSessionCardType) => void;
}

const LiveSessionCard: React.FC<LiveSessionCardProps> = ({
  session,
  enterToSession,
  isColumn,
}) => {
  const { user, geolocation } = useUser();
  const navigate = useNavigate();
  const isFree = session.free ?? true;
  const timezone = geolocation.location;

  const { showCancelOption, showEnterOption, isGeneral } =
    useLiveSessionStatus(session);

  const isSessionMentor = useMemo((): boolean => {
    if (!user?.id) return false;

    const isMainMentor =
      session.mainMentors?.some((mentor) => mentor?.id === user.id) ?? false;
    const isSecondaryMentor =
      session.secondaryMentors?.some((mentor) => mentor?.id === user.id) ??
      false;

    return isMainMentor || isSecondaryMentor;
  }, [user?.id, session.mainMentors, session.secondaryMentors]);

  const [canEnterEarly, setCanEnterEarly] = useState(false);
  const [shouldHideCard, setShouldHideCard] = useState(false);

  const checkCanEnterEarly = useCallback((): boolean => {
    if (!session.isScheduled) return false;

    const now = DateTime.now().setZone(timezone);
    const sessionStart = DateTime.fromISO(session.startDate.toString(), {
      zone: timezone,
    });
    const earlyAccessTime = sessionStart.minus({ minutes: 20 });

    return Boolean(
      isSessionMentor && now >= earlyAccessTime && now < sessionStart
    );
  }, [session.startDate, session.isScheduled, timezone, isSessionMentor]);

  useEffect(() => {
    const checkAndSetEarlyAccess = () => {
      const canEnter = checkCanEnterEarly();
      setCanEnterEarly(canEnter);

      if (!canEnter && session.isScheduled) {
        const sessionStart = DateTime.fromISO(session.startDate.toString(), {
          zone: timezone,
        });
        const earlyAccessTime = sessionStart.minus({ minutes: 20 }); // Cambiado a 20 minutos
        const now = DateTime.now().setZone(timezone);
        const msUntilEarlyAccess = earlyAccessTime.diff(now).as("milliseconds");

        if (msUntilEarlyAccess > 0) {
          setTimeout(checkAndSetEarlyAccess, msUntilEarlyAccess);
        }
      }
    };

    checkAndSetEarlyAccess();
  }, [session.startDate, session.isScheduled, timezone, checkCanEnterEarly]);

  const checkTimeConditions = useCallback(() => {
    if (!session.endDate) return false;

    const now = DateTime.now().setZone(timezone);
    const sessionEnd = DateTime.fromISO(session.endDate.toString(), {
      zone: timezone,
    });

    const hideTime = sessionEnd.minus({ minutes: 15 });

    return now >= hideTime || session.status === LiveSessionStatus.Ended;
  }, [session.endDate, timezone, session.status]);

  useEffect(() => {
    const checkAndSetVisibility = () => {
      const shouldHide = checkTimeConditions();
      setShouldHideCard(shouldHide);

      if (!shouldHide && session.endDate) {
        const sessionEnd = DateTime.fromISO(session.endDate.toString(), {
          zone: timezone,
        });
        const hideTime = sessionEnd.minus({ minutes: 15 });
        const now = DateTime.now().setZone(timezone);
        const msUntilHide = hideTime.diff(now).as("milliseconds");

        if (msUntilHide > 0) {
          setTimeout(checkAndSetVisibility, msUntilHide);
        }
      }
    };

    checkAndSetVisibility();
  }, [session.endDate, timezone, checkTimeConditions]);

  useEffect(() => {
    const checkAndSetEarlyAccess = () => {
      const canEnter = checkCanEnterEarly();
      setCanEnterEarly(canEnter);

      if (!canEnter && session.isScheduled) {
        const sessionStart = DateTime.fromISO(session.startDate.toString(), {
          zone: timezone,
        });
        const earlyAccessTime = sessionStart.minus({ minutes: 15 });
        const now = DateTime.now().setZone(timezone);
        const msUntilEarlyAccess = earlyAccessTime.diff(now).as("milliseconds");

        if (msUntilEarlyAccess > 0) {
          setTimeout(checkAndSetEarlyAccess, msUntilEarlyAccess);
        }
      }
    };

    checkAndSetEarlyAccess();
  }, [session.startDate, session.isScheduled, timezone, checkCanEnterEarly]);

  const checkCategory = (category: string) =>
    !["sin marca", "sin subcategoría", "sin nivel"].includes(
      category.toLowerCase()
    );

  const liveSessionParsedDate = parseLiveSessionsDate(
    session?.startDate ?? new Date(),
    timezone
  );

  const handleEnterSession = useCallback(() => {
    if (canEnterEarly || showEnterOption) {
      enterToSession(session);
    }
  }, [canEnterEarly, showEnterOption, enterToSession, session]);

  const mentorName =
    session?.mainMentors[0]?.name + " " + session?.mainMentors[0]?.lastName;

  const avatarUrl = useMemo(
    () => session?.mainMentors[0]?.avatarUrl,
    [session?.mainMentors[0]?.avatarUrl]
  );

  const handleCardClick = () => {
    if (showEnterOption || canEnterEarly) {
      handleEnterSession();
    }
    if (!showEnterOption || !canEnterEarly) {
      navigate(`${AppRoute.LiveSessions}/${session.id}`);
    }
  };

  if (shouldHideCard) {
    return null;
  }

  return (
    <Flex
      key={session?.name}
      alignSelf={"stretch"}
      flexGrow={1}
      minW={"300px"}
      maxW={isColumn ? "100%" : "300px"}
      maxH={"261px"}
      flexDir={"column"}
      userSelect={"none"}
      justifyContent={"space-between"}
      onClick={handleCardClick}
      cursor={"pointer"}
    >
      <Flex
        flexGrow={1}
        flexDir={"column"}
        rounded={"6px"}
        maxHeight={"189px"}
        bg={session.type?.color ?? "shades.200"}
        p={"12px"}
        gap={"8px"}
      >
        <Flex
          align="flex-start"
          justifyContent="space-between"
          whiteSpace="nowrap"
        >
          {avatarUrl ? (
            <Image
              src={avatarUrl}
              alt={mentorName}
              borderRadius="50%"
              w="40px"
              h="40px"
            />
          ) : (
            <Avatar
              w="40px"
              h="40px"
              src={session?.mainMentors[0]?.avatarUrl}
            />
          )}
          {!isFree && (
            <Badge
              px="8px"
              py="1px"
              bg="shades.white"
              textTransform="capitalize"
              w="fit-content"
              color="secondary.main"
              borderRadius="6px"
              border="1px solid"
              borderColor="#E9F1FF"
            >
              <Flex align="center" gap="4px" whiteSpace="nowrap">
                <Ico.WalletDiamond fontSize="14px" />
                <Text variant="tinyExtraBold">Plan Pro</Text>
              </Flex>
            </Badge>
          )}
        </Flex>
        <Box>
          <Text
            fontWeight={700}
            variant={"caption"}
            textTransform={"uppercase"}
            fontFamily={"Poppins"}
            fontSize={"12px"}
          >
            <Trans>{session.type?.name}</Trans>
          </Text>
          <Text fontWeight={600} variant={"caption"} h={"45px"}>
            {session?.name}
          </Text>
        </Box>
        <Box>
          <Text fontWeight={600} variant={"caption"}>
            {`${liveSessionParsedDate.format} | ${liveSessionParsedDate.time}`}
          </Text>
          <Text fontWeight={400} variant={"caption"}>
            Con: {mentorName}
          </Text>
        </Box>
      </Flex>

      <Flex align={"flex-start"} gap={"8px"} mt={"8px"}>
        {isGeneral && (
          <Badge
            key={"General"}
            px={"8px"}
            py={"2px"}
            bg={"neutral.50"}
            textTransform={"capitalize"}
            w={"fit-content"}
            color={"neutral.500"}
            borderRadius={"6px"}
          >
            <Text variant={"feedback"}>General</Text>
          </Badge>
        )}

        {!isGeneral &&
          [session?.brand, session?.subCategory, session?.level].map(
            (category) =>
              category &&
              category.length < 2 &&
              category[0] &&
              checkCategory(category[0].name) && (
                <Badge
                  key={category[0]?.id}
                  px={"8px"}
                  py={"2px"}
                  bg={"neutral.50"}
                  textTransform={"capitalize"}
                  color={"neutral.500"}
                  borderRadius={"6px"}
                >
                  <Text variant={"feedback"}>{category[0]?.name}</Text>
                </Badge>
              )
          )}
      </Flex>

      <Flex flexDirection={"row"} mt={"16px"}>
        {session?.isCancelled && (
          <Button
            fontSize={"14px"}
            lineHeight={1}
            p={0}
            height={"22px"}
            variant="link"
            isDisabled
            cursor={"not-allowed"}
          >
            <Trans>Canceled by the teacher</Trans>
          </Button>
        )}
        {(showEnterOption || canEnterEarly) && (
          <Button
            fontSize={"14px"}
            lineHeight={1}
            p={0}
            height={"22px"}
            variant={"link"}
            onClick={handleEnterSession}
            rightIcon={<Ico.ArrowRight width={"18px"} height={"18px"} />}
          >
            <Trans>Enter Live Class</Trans>
          </Button>
        )}
        {showCancelOption && !canEnterEarly && (
          <Flex alignItems="center">
            <Badge
              display="flex"
              alignItems="center"
              justifyContent="center"
              background="success.300"
              color="white"
              borderRadius="6px"
              fontSize="10px"
              fontWeight={600}
              w="73px"
              h="24px"
              lineHeight={"19px"}
            >
              Agendada
            </Badge>
            <Center height="24px" mx={"8px"}>
              <Divider orientation="vertical" />
            </Center>
          </Flex>
        )}
        {!(showEnterOption || canEnterEarly) && (
          <Button
            height={"22px"}
            fontSize={"14px"}
            lineHeight={1}
            p={0}
            variant={"link"}
            onClick={() => navigate(`${AppRoute.LiveSessions}/${session.id}`)}
          >
            <Trans>See details</Trans>
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default LiveSessionCard;
