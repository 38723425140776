import React from "react";
import {
  Flex,
  Heading,
  Image,
  ListItem,
  Text,
  UnorderedList,
  useBreakpointValue,
} from "@chakra-ui/react";
import { colors } from "@/components/Theme/colors";
import { useTranslation } from "react-i18next";

interface ProgramResumeProps {
  thumbnailUrl: string;
  programType: string;
  programName: string;
  youWillLearn: string[];
  largeDescription: string;
}

export const ProgramResume: React.FC<ProgramResumeProps> = ({
  thumbnailUrl,
  programType,
  programName,
  youWillLearn,
  largeDescription,
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const { t } = useTranslation();
  return (
    <Flex flexDir={"column"} px={{ base: 4, xl: 0 }} maxW={"944px"}>
      <Text textTransform={"uppercase"} variant={"bold"} fontSize={"12px"}>
        {t(programType)}
      </Text>
      <Heading pb={2} fontSize={"20px"} as={isMobile ? "h6" : "h4"}>
        {programName}
      </Heading>
      <Text fontSize={"14px"} pb={8}>
        {largeDescription ??
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec duiarcu. Aliquam iaculis dictum posuere. Nam aliquam vehicula sem etfermentum. Pellentesque at turpis finibus, tincidunt nunc nec, variusurna. Morbi cursus mauris ipsum, vel venenatis libero semper at. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec dui arcu. Aliquam iaculis dictum posuere. Nam aliquam vehicula sem et fermentum. Pellentesque at turpis finibus, tincidunt nunc nec, varius urna. Morbi cursus mauris ipsum, vel venenatis libero semper at."}
      </Text>
      <Flex
        gap={{ base: 4, md: 12 }}
        py={{ base: 6, md: 8 }}
        px={{ base: 4, md: 12 }}
        background={colors.neutral[50]}
        borderRadius={"6px"}
        flexDir={{ base: "column", md: "row" }}
      >
        <Flex order={{ base: 0, md: 2 }} flexDirection={"column"} gap={2}>
          <Text fontWeight={"bold"}>¿Qué aprenderás?</Text>
          <UnorderedList pb={4}>
            {Array.isArray(youWillLearn) &&
              youWillLearn.map((item) => (
                <ListItem key={item} fontSize={"14px"}>
                  {item}
                </ListItem>
              ))}
          </UnorderedList>
        </Flex>
        <Image
          w={{ base: "100%", md: "320px" }}
          h={{ base: "100%", md: "266px" }}
          borderRadius={"6px"}
          src={thumbnailUrl}
          alt="Dan Abramov"
          style={{ objectFit: "fill" }}
          alignSelf={"baseline"}
        />
      </Flex>
    </Flex>
  );
};
