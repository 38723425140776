import { EntityName } from "@/schemaTypes";

export enum FilterTypes {
  LiveSessions = "liveSessions",
  OnlinePrograms = "onlinePrograms",
  GuideTrainings = "guideTrainings",
}

export const FilterOptions = [
  {
    label: "Todos",
    value: "all",
  },
  {
    label: "Diplomados",
    value: EntityName.Diplomat,
  },
  {
    label: "Especializaciones",
    value: EntityName.Specialization,
  },
  {
    label: "Cursos",
    value: EntityName.AdvancedCourse,
  },
];
