import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

const REFERRAL_KEY = "irclickid";
const REFERRAL_EXPIRY_KEY = "irclickidExpiry";
const EXPIRY_DAYS = 30;

const saveReferralId = (referralId: string) => {
  const expiryDate = new Date();
  expiryDate.setDate(expiryDate.getDate() + EXPIRY_DAYS);
  localStorage.setItem(REFERRAL_KEY, referralId);
  localStorage.setItem(REFERRAL_EXPIRY_KEY, expiryDate.toISOString());
};

const getReferralIdFromStorage = (): string | null => {
  const referralId = localStorage.getItem(REFERRAL_KEY);
  const expiryDate = localStorage.getItem(REFERRAL_EXPIRY_KEY);

  if (!referralId || !expiryDate) return null;

  if (new Date() > new Date(expiryDate)) {
    localStorage.removeItem(REFERRAL_KEY);
    localStorage.removeItem(REFERRAL_EXPIRY_KEY);
    return null;
  }

  return referralId;
};

const clearReferralId = () => {
  localStorage.removeItem(REFERRAL_KEY);
  localStorage.removeItem(REFERRAL_EXPIRY_KEY);
};

export const useReferral = () => {
  const location = useLocation();
  const { irclickid: referralIdFromParams } = useParams<{
    irclickid: string;
  }>();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const referralIdFromQuery = urlParams.get(REFERRAL_KEY);
    const referralIdToSave = referralIdFromQuery || referralIdFromParams;

    if (referralIdToSave) {
      saveReferralId(referralIdToSave);
    }
  }, [location, referralIdFromParams]);

  return {
    referralId: getReferralIdFromStorage(),
    clearReferralId,
  };
};
