import { Ico } from "@/assets/icons";
import { Box, Button, Text } from "@chakra-ui/react";
import { Trans } from "react-i18next";
import { colors } from "@/components/Theme/colors";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "@/AppRoute";

type Props = {
  visible: boolean;
};

export const JoinPro = ({ visible }: Props) => {
  const navigate = useNavigate();

  if (!visible) {
    return null;
  }
  return (
    <Box>
      <Box py={2}>
        <Ico.Diamond fontSize="24px" color={colors.primary[100]} />
      </Box>
      <Text variant={"feedback"} py={2}>
        Accede a contenido ilimitado y herramientas exclusivas para llevar tu
        aprendizaje al siguiente nivel.
      </Text>
      <Button
        size="small"
        fontSize={"14px"}
        w={"fit-content"}
        variant="link"
        py={2}
        onClick={() => navigate(AppRoute.UpsellAndCross)}
      >
        <Trans>Suscríbete al Plan Pro</Trans>
      </Button>
    </Box>
  );
};
