import { Ico } from "@/assets/icons";
import { colors } from "@/components/Theme/colors";
import {
  Box,
  Flex,
  Stack,
  Text,
  Heading,
  Link,
  AspectRatio,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { Trans } from "react-i18next";
interface InfoCardProps {
  title: string;
  children: ReactNode;
  icon: ReactNode;
}
const InfoCard = ({ title, icon, children }: InfoCardProps) => (
  <Box
    bg="white"
    color="gray.800"
    borderRadius="md"
    boxShadow="-1px -1px 10px 0px #64646429"
    p="4"
    flex="1"
  >
    <Box
      bg={colors.tertiary[300]}
      width={"fit-content"}
      borderRadius={"full"}
      p={"5px"}
      mb={"8px"}
    >
      {icon}
    </Box>

    <Heading as="h3" size="md" color="gray.800" mb={2}>
      {title}
    </Heading>
    {children}
  </Box>
);

const ContactList = () => (
  <Stack spacing={2}>
    {[
      { country: "Colombia", phone: "+57 601 508 7866" },
      { country: "Estados Unidos", phone: "+1 934 666 1955" },
      { country: "México", phone: "+52 554 162 9272" },
      { country: "Chile", phone: "+56 225 813 926" },
      { country: "Panamá", phone: "+507 83 85 317" },
      { country: "Perú", phone: "+51 170 07 926" },
      { country: "República Dominicana", phone: "+1 829 946 2750" },
      { country: "Otros países", phone: "+1 934 666 1955" },
    ].map(({ country, phone }) => (
      <Flex key={country} justify="space-between">
        <Text>{country}</Text>
        <Link
          href={`tel:${phone}`}
          color={colors.primary[300]}
          textDecoration={"unset"}
        >
          {phone}
        </Link>
      </Flex>
    ))}
  </Stack>
);
export const ContactUs = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <>
      {!isMobile && (
        <AspectRatio h="449px" ratio={16 / 9}>
          <Image
            src="/img/sb-banner-contactenos.png"
            objectFit="contain"
            position="absolute"
          />
        </AspectRatio>
      )}
      {isMobile && (
        <Box
          h="489px"
          bg="#1F1E24"
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
        >
          <Image
            src="/img/sb-banner-contactenos-mobile.png"
            objectFit="contain"
            h="319px"
          />
        </Box>
      )}

      <Flex
        flexDir={"column"}
        align="center"
        position="relative"
        zIndex={1}
        px={4}
        gap={isMobile ? "300px" : "101px"}
        mt={isMobile ? "-460px" : "-280px"}
      >
        <Flex
          textAlign="left"
          maxW="912px"
          w="full"
          mx={4}
          direction="column"
          color={"white"}
        >
          <Heading fontSize={isMobile ? "18px" : "24px"} mb={2}>
            <Trans>We are here to help you.</Trans>
          </Heading>
          <Text fontSize="sm" maxW="475px">
            <Trans>
              We want to ensure we provide you with the best support to help you
              achieve your digital entrepreneurship goals. Contact us through
              any of the following channels:
            </Trans>
          </Text>
        </Flex>
        <Flex
          w="full"
          maxW="912px"
          gap={6}
          direction={{ base: "column-reverse", md: "row" }}
          pb={"51px"}
        >
          <InfoCard title="Llámanos" icon={<Ico.Phone />}>
            <Text fontSize="sm" fontWeight={"bold"}>
              <Trans>Customer service hours:</Trans>
            </Text>
            <Text fontSize="sm" mb={4}>
              <Trans>
                Monday - Friday from 8:00am - 9:00pm (Colombia time)
              </Trans>
            </Text>
            <ContactList />
          </InfoCard>

          <InfoCard title="Chatea ahora" icon={<Ico.MessageText />}>
            <Text fontSize="sm">
              <Trans>
                Use the chat located at the bottom of this page to get quick
                assistance with your account and much more.
              </Trans>
            </Text>
          </InfoCard>
        </Flex>
      </Flex>
    </>
  );
};
