import React, { createContext, useMemo, ReactNode } from "react";
import { useConnectedClient } from "@/components/LiveSessionsZoom/Hooks/useConnectedClient";
import { MentorContextType } from "./types";
import { useUser } from "@/providers/useUser";

export const MentorContext = createContext<MentorContextType>({
  isMentorOrAdmin: false,
  isMainMentor: false,
  session: undefined,
});

interface MentorProviderProps {
  children: ReactNode;
}

export const MentorProvider: React.FC<MentorProviderProps> = ({ children }) => {
  const { user: userData } = useUser();
  const { dataAuthJWT } = useConnectedClient();

  const session = dataAuthJWT?.getJwtAuthLiveSession?.session;

  const isMentorOrAdmin = useMemo(() => {
    return Boolean(
      userData?.id &&
        session &&
        (session.mainMentors?.some((mentor) => mentor?.id === userData.id) ||
          session.secondaryMentors?.some(
            (mentor) => mentor?.id === userData.id
          ))
    );
  }, [userData?.id, session?.mainMentors, session?.secondaryMentors]);

  const isMainMentor = useMemo(() => {
    return Boolean(
      userData?.id &&
        session?.mainMentors?.some((mentor) => mentor?.id === userData.id)
    );
  }, [userData?.id, session?.mainMentors]);

  const value = {
    isMentorOrAdmin,
    isMainMentor,
    session,
  };

  return (
    <MentorContext.Provider value={value}>{children}</MentorContext.Provider>
  );
};
